import React from "react";
import "./FourInfoBlock.scss";

class FourInfoBlock extends React.Component {

  componentDidMount(){
    const section_id = this.props.four_block_data.section_id && this.props.four_block_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }

  render() {
    var four_block_data = this.props.four_block_data;
    const section_id = this.props.four_block_data.section_id && this.props.four_block_data.section_id.trim();
    var image_class_add =
      four_block_data.image !== false ? "col-lg-7" : "col-lg-12";
    var inner_class_add =
      four_block_data.image !== false ? "col-lg-6" : "col-lg-5";
    var toShow;
    return four_block_data.grid_section.length > 0 ? (
      <section className="fourinfo-section" id={section_id}>
        <div className="container">
          <div className="row blocks-image">
            {four_block_data.image_position === "right"
              ? [
                  <div className={image_class_add + " four-blocks"}>
                    <div className="row">
                      {four_block_data.grid_section.length > 0
                        ? four_block_data.grid_section.map(items =>
                            items.headline && items.description ? (
                              <div className={inner_class_add + " block-info"}>
                                <div className="special-case">
                                  {items.icon.url ? (
                                    <div className="icon-image">
                                      <img
                                        src={items.icon.url}
                                        title={items.icon.title}
                                        alt={items.icon.title}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {items.headline ? (
                                    <div>
                                      <h2
                                        className="title"
                                        dangerouslySetInnerHTML={{
                                          __html: items.headline.replace(
                                            /(?:\r\n|\r|\n)/g,
                                            ""
                                          )
                                        }}
                                      ></h2>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {items.description ? (
                                  <p
                                    className="text"
                                    dangerouslySetInnerHTML={{
                                      __html: items.description.replace(
                                        /(?:\r\n|\r|\n)/g,
                                        ""
                                      )
                                    }}
                                  ></p>
                                ) : (
                                  ""
                                )}
                                {items.cta_label && items.cta_link ? (
                                  <a
                                    href={items.cta_link}
                                    className="learn-more"
                                    title={items.cta_label}
                                  >
                                    {items.cta_label}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )
                          )
                        : ""}
                    </div>
                  </div>,
                  four_block_data.image !== false ? (
                    <div className="col-lg-5">
                      <div className="side-image">
                        <img
                          src={four_block_data.image.url}
                          title={four_block_data.image.title}
                          alt={four_block_data.image.title}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ]
              : [
                  four_block_data.image !== false ? (
                    <div className="col-lg-5">
                      <div className="side-image">
                        <img
                          src={four_block_data.image.url}
                          title={four_block_data.image.title}
                          alt={four_block_data.image.title}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  ),
                  <div className={image_class_add + " four-blocks"}>
                    <div className="row">
                      {four_block_data.grid_section.length > 0
                        ? four_block_data.grid_section.map(items => (
                            <div className={inner_class_add + " block-info"}>
                              <div className="special-case">
                                {items.icon.url ? (
                                  <div className="icon-image">
                                    <img
                                      src={items.icon.url}
                                      title={items.icon.title}
                                      alt={items.icon.title}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {items.headline ? (
                                  <div>
                                    <h2
                                      className="title"
                                      dangerouslySetInnerHTML={{
                                        __html: items.headline.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          ""
                                        )
                                      }}
                                    ></h2>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {items.description ? (
                                <p
                                  className="text"
                                  dangerouslySetInnerHTML={{
                                    __html: items.description.replace(
                                      /(?:\r\n|\r|\n)/g,
                                      ""
                                    )
                                  }}
                                ></p>
                              ) : (
                                ""
                              )}
                              {items.cta_label && items.cta_link ? (
                                <a
                                  href={items.cta_link}
                                  className="learn-more"
                                  title={items.cta_label}
                                >
                                  {items.cta_label}
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>
                ]}
          </div>
        </div>
      </section>
    ) : (
      ""
    );
  }
}

export default FourInfoBlock;
