import React from "react";
import axios from 'axios';
import {apiConfig} from '../Components/Helper.jsx';
import { Link , useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie';

const LoginButton = () => {

  const { user,loginWithRedirect,isAuthenticated} = useAuth0();
  const history = useHistory();

//   var user_account_layer = Cookies.get('user_account_layer');
//   if((user_account_layer == '' || user_account_layer == undefined) && isAuthenticated == true && user.sub != ''){
//     axios.post(apiConfig().API_URL+'/get_user_info', {
//       "user_id" : user.sub
//     },
//     {
//       headers: {
//         Authorization: 'Aa147147147#'
//       }
//     }
//     )
//     .then(response => {
//         if(response.data.data.login_count > 1){
//           window.dataLayer.push({
//             'event': 'accountLogin',
//           });
//         }
//         else if(response.data.data.login_count == 1){
//           window.dataLayer.push({
//             'event': 'createAccount',
//           });
//         }
//     })

//     console.log("Layer Done");
// }

  var last_url = Cookies.get('last_url');
  if(last_url != '' && last_url != undefined){
    history.push(last_url);
    Cookies.set('last_url','',{ expires: 2 });

  }

  //Account Login Data Layer Trigger
  var user_account_layer = Cookies.get('user_account_layer');
  if(user_account_layer != true && isAuthenticated == true && user.sub != ''){
      axios.post(apiConfig().API_URL+'/get_user_info', {
        "altis_user_id" : user.sub
      },
      {
        headers: {
          Authorization: 'Aa147147147#'
        }
      }
      )
      .then(response => {
          if(response.data.data.login_count > 1){
            window.dataLayer.push({
              'event': 'accountLogin',
            });
          }
          else if(response.data.data.login_count == 1){
            window.dataLayer.push({
              'event': 'createAccount',
            });
          }
          Cookies.set('user_account_layer',true,{ expires: 1 })
      })
  }
  
function create_button_click(){
    var current_url = window.location.href;
    if(current_url===window.location.origin+"/") {
      window.dataLayer.push({
        'event': 'DivisionName',
        'divisionName': 'Home Page'
      });
    }  else {
    var parts = current_url.split("/");
    var last_part = parts[parts.length-2];
    var str_uppercase = last_part.replace(/(^|' ')./g, s => s.slice(-1).toUpperCase());
    var result = str_uppercase.replace(/-/g, ' ');

    window.dataLayer.push({
      'event': 'DivisionName',
      'divisionName': result
    });
  }
}
  function  login_redirection(){
    Cookies.set('last_url',window.location.pathname,{ expires: 2 })
    loginWithRedirect();
  }

  return !isAuthenticated ? [
    <li className="header_login_button">
      <a href="javascript:void(0)" onClick={() => login_redirection()}>Login/Sign Up</a>
      <ul>
        <li><a href="javascript:void(0)" className="btnLink signIn d-flex headerLink" onClick={() => login_redirection()}>Sign In</a></li>
        <li><a href="javascript:void(0)" className="btnLink signIn d-flex headerLink" onClick={() => {create_button_click(); login_redirection()}}>Create Account</a></li>
      </ul>
    </li>
  ]
  : [
    <li className="header_login_button">
    <Link to="/my_account" href="javascript:void(0)">Profile Info</Link>
    <ul>
      <li>    <Link to="/my_account" className="btnLink createAcc d-flex headerLink">My Account</Link></li>
    </ul>
  </li>
  ];
};

export default LoginButton;