import React from 'react';
import './BlogSection.scss';
import HeadingSection from './HeadingSection';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
    nav: false,
    dots: false,
    loop : false,
    responsive : {
        0:{
            items: 1,
            stagePadding: 50,
            margin:25
        },
        580:{
            items: 2,
            margin:40,
            stagePadding: 50,
        },
        1280:{
            items: 3,
            margin:50
        },
        // 1366:{
        //     items: 4,
        //     margin:25,
        // },
        // 1680:{
        //     items: 4,
        //     margin:60
        // }
    }
}
class BlogSection extends React.Component {  
    
    componentDidMount(){
        const section_id = this.props.three_block_data.section_id && this.props.three_block_data.section_id.trim();
        if (section_id) {
          setTimeout(function () {
              if (window.location.href.indexOf("#" + section_id) > -1) {
                  document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
              }
          }, 2000)
      }
    }
  render() {   
    var three_block_data = this.props.three_block_data ;    
    var window_width = window.screen.width; 
    var backgroundImage = this.props.three_block_data.side_border; 
    if (backgroundImage !== "") {
        var background_image = {
            backgroundImage: 'url(' + backgroundImage + ')',
        }
    }
    const section_id = this.props.three_block_data.section_id && this.props.three_block_data.section_id.trim();
     return (  
      three_block_data.headline && three_block_data.description && (three_block_data.grid_section.length > 0)? (
          <div className="blog-section" id={section_id}> 
                <div className="container">
                    <div className="row blog-content" style={background_image}>
                            <div className="header-section">
                                <div className="heading-section-left">
                                    <HeadingSection 
                                    subtitle={three_block_data.sub_text} 
                                    title_part_1={three_block_data.headline}    
                                    title_part_2={three_block_data.headline_part_2}  
                                    headline_part_1_color = {three_block_data.headline_part_1_color}  
                                    headline_part_2_color = {three_block_data.headline_part_2_color}  
                                    />
                                </div>
                                <div className="heading-section-right">
                                {three_block_data.description ? (
                                    <p className="title-info" dangerouslySetInnerHTML={{__html:three_block_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                ):('')}
                                {three_block_data.cta_label && three_block_data.cta_link ? (
                                    <a href={three_block_data.cta_link} title={three_block_data.cta_label} className="learn-more">{three_block_data.cta_label}</a>
                                ):('')}
                                </div>
                            </div>
                            {three_block_data.grid_section.length > 0 ? [
                                (window_width >= 1366) ? (  
                                <div className="blog-info-div blog-content-width">
                                        {three_block_data.grid_section.map((items,key) => (
                                            items.image.url && (items.headline || items.description) ? (
                                                <div className="blog-info">
                                                    <div className="blog-img">
                                                        {items.image.url ? (
                                                            <img src={items.image.url} title={items.image.title} alt={items.image.title} />
                                                        ):('')}
                                                    </div>
                                                    {items.headline ? (
                                                        <h6>{key+1+". "}{items.headline}</h6>
                                                    ):('')}
                                                    {items.description ? (
                                                        <p className="blog-text" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                                    ):('')}  
                                                </div>  
                                            ):('')
                                        ))}
                                </div>)
                                :(
                                    <div className="blog-info-div">
                                    <OwlCarousel {...options} className="owl-theme"> 
                                        {three_block_data.grid_section.map((items,key) => (
                                            items.image.url && (items.headline || items.description) ? (
                                                <div className="blog-info">
                                                    <div className="blog-img">
                                                        {items.image.url ? (
                                                            <img src={items.image.url} title={items.image.title} alt={items.image.title} />
                                                        ):('')}
                                                    </div>
                                                    {items.headline ? (
                                                        <h6>{key+1+". "}{items.headline}</h6>
                                                    ):('')}
                                                    {items.description ? (
                                                        <p className="blog-text" dangerouslySetInnerHTML={{__html:items.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                                    ):('')}  
                                                </div>  
                                            ):('')
                                        ))}
                                    </OwlCarousel> 
                                </div>)
                            ]:['']}
                    </div>
                </div>
          </div> 
        ):('') 
     );  
  }  
}  

export default BlogSection; 