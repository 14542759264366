import React from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import './GalleryModal.scss';

class GalleryModal extends React.Component {  
    componentWillMount(){
        setTimeout(() => {
            var swiper = new Swiper('.swiper-container', {
                initialSlide:this.props.selected_index,
                direction: 'vertical',
                calculateHeight: false,
                autoHeight: true,
                mousewheel: true,
                pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
                },
            });
            console.log('swiper is init.');
        }, 0);
    }
    render() {   
    var gallery = this.props.gallery ; 
 
    return (
        <div className="swiper-container">
            <div className="swiper-wrapper">
            {gallery.gallery_images.map(items => ( 
                <div className="swiper-slide">
                    <img src={items.image.url} title={items.image.image_alt} alt={items.image.image_alt}/>
                    <p>{items.image_caption}</p>
                </div>
            ))}
            </div>
            <div className="swiper-pagination"></div>
        </div>
      );  
    }  
}

export default GalleryModal;