import React from 'react';
import axios from 'axios';
import HomeBanner from './HomeBanner';
import PlanCard from './PlanCard';
import ScheduleTour from './ScheduleTour';
import GenericBlock from './GenericBlock';
import SpringPromo from './SpringPromo';
import Testimonials from './Testimonials';
import FocusBoxes from './FocusBoxes';
import PhotoGallery from './PhotoGallery';
import GridSection from './GridSection';
import BlogSection from './BlogSection';
import VideoBlock from './VideoBlock';
import FullImageBlock from './FullImageBlock';
import MortgageSection from './MortgageSection';
import FlexiableContent from './FlexiableContent';
import LivingSmart from './LivingSmart';
import IframeModal from './IframeModal';
import FourInfoBlock from './FourInfoBlock';
import TeamSection from './TeamSection';
import QmiCard from './QmiCard';
import LivingSmartTabbed from './LivingSmartTabbed';
import Gallery from './Gallery';
import SectionTitle from './SectionTitle';
import GenericHero from './GenericHero';
import BrokersFile from './BrokersFile';
import PostSection from './PostSection';
import LifestyleGlobal from './LifestyleGlobal';
import { apiConfig } from './Helper.jsx';
import $ from 'jquery';
import CommBanner from './CommBanner';

class GlobalField extends React.Component {

  render() {
    var global_component = this.props.global_data;
    var  updated_lifestyle_post = null;
    const category_list = [];
    if(global_component !== null){
        global_component.map(items => [
          items.acf_fc_layout === "lifestyle_categories_block" ? (
            updated_lifestyle_post =  items && items.lifestyle_data &&  items.lifestyle_data.map((item,i)=>{
              const all_cat = item.category.map((obj,index)=>{
                if(!category_list.filter(list_item=>list_item.slug===obj.slug).length){
                  category_list.push({name:obj.name,slug:obj.slug,description:obj.description});
                }
                return obj.name
              });
              return {...item,all_cat};
            })
          ):('')
      ]);
    }
    return (
      <div>
        {global_component !== null ? (
          global_component.map(items => [
            items.acf_fc_layout === "home_banner" ? (
              <HomeBanner banner_data={items}/>
            ) : (''),
            items.acf_fc_layout === "staggered_image_content_block" ? (
              <ScheduleTour staggered_image_content_block={items} />
            ) : (''),
            items.acf_fc_layout === "generic_block" ? (
              <GenericBlock generic_block={items} target_change={items.open_in_new_tab} hide_component={false} />
            ) : (''),
            items.acf_fc_layout === "design_studio_block" ? (
              <GenericBlock generic_block={items} target_change={true} hide_component={items.hide_design_studio} />
            ) : (''),
            items.acf_fc_layout === "call_to_action" ? [
              <SpringPromo promo_data={items} />,
              <Testimonials testimonial_data={items} />
            ] : [''],
            items.acf_fc_layout === "focus_boxes_with_icons" ? (
              <FocusBoxes focus_data={items} />
            ) : (''),
            items.acf_fc_layout === "media_gallery" ? (
              <PhotoGallery media_gallery={items} />
            ) : (''),
            items.acf_fc_layout === "three_up_info_block" ? (
              <BlogSection three_block_data={items} />
            ) : (''),
            items.acf_fc_layout === "focus_boxes" ? (
              <GridSection grid_data={items} />
            ) : (''),
            items.acf_fc_layout === "video_block" ? (
              <VideoBlock video_data={items} />
            ) : (''),
            items.acf_fc_layout === "mortgage_calculator" ? (
              <MortgageSection mortgage_data={items} />
            ) : (''),
            items.acf_fc_layout === "full_image_block" ? (
              <FullImageBlock full_image_block={items} />
            ) : (''),
            items.acf_fc_layout === "inline_component" ? (
              <IframeModal iframe_data={items} />
            ) : (''),
            items.acf_fc_layout === "diagonal_content_block" ? (
              <FlexiableContent blogs_data={items} />
            ) : (''),
            items.acf_fc_layout === "flexible_content_block" ? (
              <LivingSmart living_smart_data={items} />
            ) : (''),
            items.acf_fc_layout === "four_up_info_block" ? (
              <FourInfoBlock four_block_data={items} />
            ) : (''),
            items.acf_fc_layout === "local_team" ? (
              <TeamSection team_data={items} />
            ) : (''),
            items.acf_fc_layout === "living_smart_tabbed" ? (
              <LivingSmartTabbed living_smart_tabbed={items} />
            ) : (''),
            items.acf_fc_layout === "gallery_variation_2" ? (
              <Gallery gallery_v2={items} />
            ) : (''),
            items.acf_fc_layout === "generic_hero" ? (
              <GenericHero generic_hero={items} />
            ) : (''),
            items.acf_fc_layout === "section_title" ? (
              <SectionTitle
                subtext={items.section_eyebrow_text}
                title_part_1={items.section_title_headline}
                title_part_2={items.headline_part_2}
                headline_part_1_color={items.headline_part_1_color}
                headline_part_2_color={items.headline_part_2_color}
                section_id = {items.section_id}
              />
            ) : (''),
            items.acf_fc_layout === "brokers_details" ? (
              <BrokersFile brokers_details={items} />
            ) : (''),
            items.acf_fc_layout === "homepage_communityneighborhood_feature" ? (
              <PlanCard plan_detail={items.community} sectiondata={items} data_name='global' section_id = {items.section_id}/>
            ) : (''),
            items.acf_fc_layout === "lifestyle_categories_block" ? (
              <LifestyleGlobal updated_lifestyle_post={updated_lifestyle_post} category_list={items.lifestyle_category} section_id = {items.section_id}/>
            ) : (''),
            items.acf_fc_layout === "blog_feature_block" ? (
              <PostSection post_data={items.post_detial} section_id = {items.section_id}/>
            ) : ('')
          ])
        ) : ('')}
      </div>
    );
  }
}

export default GlobalField; 