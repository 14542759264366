import React from 'react';
import { Modal } from 'react-bootstrap'
import './VideoBlock.scss';
import HeadingSection from './HeadingSection';

class VideoBlock extends React.Component {
  constructor() {
    super();
    this.state = {
      showHide: false
    }
  }
  componentDidMount(){
    const section_id = this.props.video_data.section_id && this.props.video_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    } 
  }
  // Video Object Structured Data
  video_object_structured_data(video_data) {
    if (video_data) {
      if (video_data.video_cover_image.url) {
        var thumbnailUrl = video_data.video_cover_image.url;
      }
      if (video_data.video_cover_image.date) {
        var uploadDate = video_data.video_cover_image.date;
      }
      if (video_data.video_link) {
        var embedUrl = video_data.video_link;
      }
      if (video_data.description) {
        var description = video_data.description.replace(/(<([^>]+)>)/ig, '');
      }
    }
    const script = document.createElement("script");
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Altis 55+",
      "description": "${description}",
      "thumbnailUrl": "${thumbnailUrl}",
      "uploadDate": "${uploadDate}",
      "embedUrl": "${embedUrl}"
    }`
    //  Structure Data script end 
    document.head.appendChild(script);
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }
  videoLaunch_data_layer_push(param) {
    this.setState({ value: param })
    if(param && param==='videoLaunch') {
        window.dataLayer.push({'event':'videoLaunch'});
    }
  }
  render() {
    var video_data = this.props.video_data;
    if (video_data) {
      this.video_object_structured_data(video_data);
    }
    const section_id = this.props.video_data.section_id && this.props.video_data.section_id.trim();

    return (

      video_data.sub_text || video_data.headline || video_data.description ? (
        <section className="video-block-section" id={section_id}>
          <div className="container video-container">
            <div className="video-main-div" onClick={() => this.videoLaunch_data_layer_push('videoLaunch')}>
              {video_data.video_cover_image ? (
                <a href="javascript:void(0)" onClick={() => this.handleModalShowHide()} className="video-iframe divide-equal" title="video image">
                  <img width="100%" height="100%" src={video_data.video_cover_image.url} title={video_data.video_cover_image.title} alt={video_data.video_cover_image.title} />
                  {video_data.video_link ? (
                    <span className="videoArrow"></span>
                  ) : ('')}
                </a>
              ) : ('')}
              <div className="video-info divide-equal">
                <HeadingSection
                  subtitle={video_data.sub_text}
                  title_part_1={video_data.headline}
                  title_part_2={video_data.headline_part_2}
                  headline_part_1_color={video_data.headline_part_1_color}
                  headline_part_2_color={video_data.headline_part_2_color}
                />
                {video_data.description ? (<p className="title-info" dangerouslySetInnerHTML={{ __html: video_data.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>) : ('')}
                {video_data.cta_label && video_data.cta_link ? (
                  <a href={video_data.cta_link} className="learn-more" title={video_data.cta_label}>{video_data.cta_label}</a>
                ) : ('')}
              </div>
              {video_data.video_link ? (
                <Modal className="interactive_modal" show={this.state.showHide}>
                  <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                    <Modal.Title>
                      <h2>{video_data.iframe_heading}</h2>
                      <p>{video_data.iframe_subtext}</p>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body> <iframe src={video_data.video_link} className="interactive_frame" title="interactive iframe"></iframe></Modal.Body>
                </Modal>
              ) : ('')}
            </div>
          </div>
        </section>
      ) : ('')

    );
  }
}

export default VideoBlock; 