import React from 'react';
import './ScheduleTour.scss';
import HeadingSection from './HeadingSection';

class ScheduleTour extends React.Component {  

    componentDidMount(){
        const section_id = this.props.staggered_image_content_block.section_id && this.props.staggered_image_content_block.section_id.trim();
        if (section_id) {
          setTimeout(function () {
              if (window.location.href.indexOf("#" + section_id) > -1) {
                  document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
              }
          }, 2000)
        }
    }

  render() {  
    var staggered_data = this.props.staggered_image_content_block;
    var layout_class = staggered_data.image_placement;
    var sideBorderImage = staggered_data.side_border;
    
    var backgroundImage = staggered_data.main_image.url;
    var borderPlacement;

    if(sideBorderImage){
        var side_border_image = {
            backgroundImage:'url('+sideBorderImage+')',
        }
        borderPlacement = staggered_data.border_placement === "right" ? 'right-side-border' : 'left-side-border';
    }

    if(backgroundImage){
        var background_image = {
            backgroundImage:'url('+backgroundImage+')',
        }
    }
    const section_id = this.props.staggered_image_content_block.section_id && this.props.staggered_image_content_block.section_id.trim();
     return ( 
        <section className={"schedule-tour-section "+(borderPlacement || '')} id={section_id}>
            <div className="container">
                <div className={"row schedule-tour "+(layout_class || '')} style={side_border_image}>
                    <div className="two-image">
                        <div className="image-section" style={background_image}>
                            {/* {staggered_data.pattern_image ? (
                                <div className="image-building">
                                    <img src={staggered_data.pattern_image.url}  className="image-building-image" title={staggered_data.pattern_image.title} alt={staggered_data.pattern_image.title}/>
                                </div>
                            ):('')} */}
                            {staggered_data.small_image && staggered_data.medium_image ? (
                                <div className="image-building small-img">
                                    <img src={staggered_data.small_image}  className="image-building-image" title="small image" alt="small image"/>
                                </div>
                            ):('')}
                            {staggered_data.medium_image && staggered_data.small_image ? (
                                <div className="image-building med-img">
                                    <img src={staggered_data.medium_image}  className="image-building-image" title="medium image" alt="medium image"/>
                                </div>
                            ):('')}
                        </div>
                    </div>
                    <div className="content-section">
                        <HeadingSection 
                        subtitle={staggered_data.sub_text} 
                        title_part_1={staggered_data.headline}    
                        title_part_2={staggered_data.headline_part_2}  
                        headline_part_1_color = {staggered_data.headline_part_1_color}  
                        headline_part_2_color = {staggered_data.headline_part_2_color}  
                        />
                        {staggered_data.description ? (
                            <p className="description" dangerouslySetInnerHTML={{__html:staggered_data.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                        ):('')}
                        {staggered_data.cta_label && staggered_data.cta_link ? (
                            <a href={staggered_data.cta_link}  title={staggered_data.cta_label} className="tour-schedule">{staggered_data.cta_label}</a>
                        ):('')}
                    </div>
                </div>
            </div>
        </section>
      );  
    }  
  }  
  
  export default ScheduleTour; 