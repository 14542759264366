import React from 'react';
import './FlexiableContent.scss';
import HeadingSection from './HeadingSection';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
    nav: false,
    dots: false,
    loop: false,

    responsive: {
        0: {
            items: 1,
            stagePadding: 40,
            margin: 20
        },
        680: {
            items: 2,
            margin: 30
        }
    }
}
class FlexiableContent extends React.Component {
    componentDidMount(){
        const section_id = this.props.blogs_data.section_id && this.props.blogs_data.section_id.trim();
        if (section_id) {
          setTimeout(function () {
              if (window.location.href.indexOf("#" + section_id) > -1) {
                  document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
              }
          }, 2000)
        }
      }
    render() {
        var window_width = window.screen.width;
        var blogs_data = this.props.blogs_data;
        var toShow;
        const section_id = this.props.blogs_data.section_id && this.props.blogs_data.section_id.trim();
        return (
            blogs_data.blogs.length > 0 ? [
                (window_width > 1024) ? (
                    <section className="flexiable-block" id={section_id}>
                        <div className="flexiable-section">
                            <div className="container flexible-sec">
                                <div className="row">
                                    {blogs_data.blogs.map(items => (
                                        <div className="flexiable-content">
                                            <div className="flexibale-img">
                                                {items.card_image ? (
                                                    <img src={items.card_image.url} title={items.card_image.title} alt={items.card_image.title} />
                                                ) : ('')}
                                            </div>
                                            <div className="flexiable-info">
                                                <HeadingSection
                                                    subtitle={items.sub_text}
                                                    title={items.post_title}
                                                    highlight_class=''
                                                    highlight_dir='left'
                                                    highlighted_word_count={2}
                                                    text_class='' />
                                                {items.post_content ? (
                                                    toShow = items.post_content.substring(0, 200) + "...",
                                                    <p className="title-info" dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                                ) : ('')}
                                                {items.blog_url ? (
                                                    <a href={items.blog_url} className="learn-more" title="learn more">Learn More</a>
                                                ) : ('')}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </section>
                )
                    : (
                        <section className="flexiable-block" id={section_id}>
                            <div className="container">
                                <div className="row">
                                    <div className="flexiable-section">
                                        <OwlCarousel {...options} className="owl-theme">
                                            {blogs_data.blogs.map(items => (
                                                <div className="flexiable-content">
                                                    <div className="flexibale-img">
                                                        {items.card_image ? (
                                                            <img src={items.card_image.url} title={items.card_image.title} alt={items.card_image.title} />
                                                        ) : ('')}
                                                    </div>
                                                    <div className="flexiable-info">
                                                        <HeadingSection
                                                            subtitle={items.sub_text}
                                                            title={items.post_title}
                                                            highlight_class=''
                                                            highlight_dir='left'
                                                            highlighted_word_count={2}
                                                            text_class='' />
                                                        {items.post_content ? (
                                                            toShow = items.post_content.substring(0, 200) + "...",
                                                            <p className="title-info" dangerouslySetInnerHTML={{ __html: toShow.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                                        ) : ('')}
                                                        {items.cta_link ? (
                                                            <a href={items.cta_link} className="learn-more" title="learn more">Learn More</a>
                                                        ) : ('')}
                                                    </div>
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
            ] : ['']
        );
    }
}

export default FlexiableContent; 