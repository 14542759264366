import React from 'react';
import { Modal } from 'react-bootstrap'
import './IframeModal.scss';
import HeadingSection from './HeadingSection';


class IframeModal extends React.Component {
    constructor() {
        super();
        this.state = {
            showHide: false
        }
    }
    componentDidMount(){
        const section_id = this.props.iframe_data.section_id && this.props.iframe_data.section_id.trim();
        if (section_id) {
          setTimeout(function () {
              if (window.location.href.indexOf("#" + section_id) > -1) {
                  document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
              }
          }, 2000)
        }
    }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }
    data_layer_push(param) {
        this.setState({ value: param })

        if (param && param === 'Virtual_Tour') {
            window.dataLayer.push({ 'event': 'virtualTour' });
        }
    }
    render() {
        var iframe_data = this.props.iframe_data;
        var backgroundImage = iframe_data.side_border;
        if(backgroundImage !==""){
            var background_image = {
                backgroundImage:'url('+backgroundImage+')',
            }
        }
        const section_id = this.props.iframe_data.section_id && this.props.iframe_data.section_id.trim();
        return (
            iframe_data.headline && iframe_data.image !== false ? (
                <section className="iframe-modal-section" id={section_id}>
                    <div className="container">
                        <div className="row iframe-main-div" style={background_image}>
                            <div className="iframe-content divide-equal">
                                <div className="iframe-content-section">
                                    <HeadingSection
                                        subtitle={iframe_data.sub_text}
                                        title_part_1={iframe_data.headline}
                                        title_part_2={iframe_data.headline_part_2}
                                        headline_part_1_color={iframe_data.headline_part_1_color}
                                        headline_part_2_color={iframe_data.headline_part_2_color}
                                    />
                                    {iframe_data.description ? (
                                        <p className="iframe-description" dangerouslySetInnerHTML={{ __html: iframe_data.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                    ) : ('')}
                                    {iframe_data.cta_label && iframe_data.cta_link ? (
                                        <a href="javascript:void(0);" title={iframe_data.cta_label} onClick={() => { this.handleModalShowHide(); this.data_layer_push('Virtual_Tour'); }} className="iframe-button" >{iframe_data.cta_label}</a>
                                    ) : ('')}
                                </div>
                            </div>
                            {iframe_data.image !== false ? (
                                <div className="iframe-image divide-equal">
                                    {iframe_data.image.url ? (
                                        <img src={iframe_data.image.url} title={iframe_data.image.title} alt={iframe_data.image.title} className="iframe-modal-image" />
                                    ) : ('')}
                                    {iframe_data.cta_link ? (
                                        <div className="iframe-modal-button">
                                            <a href="javascript:void(0);" title="plus" onClick={() => { this.handleModalShowHide(); this.data_layer_push('Virtual_Tour'); }} className="iframe-view-more">+</a>
                                        </div>
                                    ) : ('')}
                                </div>
                            ) : ('')}
                            <Modal className="sitemap_modal" show={this.state.showHide}>
                                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                                    <Modal.Title>
                                        <h2>{iframe_data.iframe_heading}</h2>
                                        <p>{iframe_data.iframe_subtext}</p>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {iframe_data.cta_link ? (
                                        <iframe src={iframe_data.cta_link} className="sitemap_frame" title="sitemap iframe" ></iframe>
                                    ) : ('')}
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </section>
            ) : ('')
        );
    }
}

export default IframeModal;

