import React from 'react';
import axios from 'axios';
import GlobalField from '../../Components/GlobalField';
import {apiConfig} from '../../Components/Helper.jsx';
import FaqSection from '../../Components/FaqSection';
import TPHLoader from '../../Components/TPHLoader.jsx';
import NoMatch from './NoMatch.jsx';
import { Redirect } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
class StaticPage extends React.Component {  
  state ={
    static_component : [],
    success : false,
    message : '',
  }

  fetch_data(){
    axios.get(apiConfig().API_URL+'/static_info/'+ this.props.match.params.slug)
    .then(response => {
      this.setState({      
        static_component : response.data.data,
        success: response.data.success,
        message : response.data.message,
      })
      console.log(response);
    })
  }

  componentDidMount(){
    this.fetch_data();
    window.CallTrk.swap();
    window.scrollTo(0,0);
  }
  componentDidUpdate() {
    window.CallTrk.swap();
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.location.pathname != nextProps.location.pathname){
      this.props = nextProps;
      this.setState({
        success: false
      })
      this.fetch_data();
    }
    
  } 
  aba_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='Accept') {
        window.dataLayer.push({'event':'prequalification'});
    }
}
  render() {    
    var success = this.state.success;
    var message = this.state.message; 
    var static_component = this.state.static_component;
    const meta = {
      title: static_component.page_title,
      description: static_component.page_desc,
      canonical: window.location.href
    };
     return (  
        <>
        <DocumentMeta {...meta} />
          {success === true ? [ 
            <div className="global-main"> 
                <GlobalField global_data={static_component.component}/>
                <FaqSection community_details={static_component}/>
                {static_component.static_content && <div className="staic-content" dangerouslySetInnerHTML={{__html:static_component.static_content.replace(/(?:\r\n|\r|\n)/g, '')}}></div>}
               
                  {static_component.static_button_content ? ( 
                    <div className="static_button_content">
                    {static_component.static_button_content.map(items => (
                      items.button_link && items.static_button ? (
                        <a href={items.button_link}  style={{backgroundColor:items.button_color }} className="learn-more" title={items.static_button} onClick={() =>  this.aba_data_layer_push(items.static_button)}>{items.static_button}</a>
                      ):('')
                    ))}
                    </div>
                  ):('')}
            </div>
          ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]}
        </>
        
     );  
  }  
}  

export default StaticPage; 