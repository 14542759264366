import React from 'react';
import './Residence.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ResidenceScreen1 from './ResidenceScreen1';
import ResidenceScreen2 from './ResidenceScreen2';
import ResidenceScreen3 from './ResidenceScreen3';
import ResidenceScreen4 from './ResidenceScreen4';
import ResidenceScreen5 from './ResidenceScreen5';
import ResidenceScreen6 from './ResidenceScreen6';
import MortgageSection from './MortgageSection';
import FloorplanSave from './FloorplanSave';
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import $, { event } from 'jquery';

const plan_options = {
    nav: false,
    dots: false,
    items: 1,
    responsive: {
        0: {
            items: 1,
            margin: 15,
            stagePadding: 30
        },
        667: {
            items: 2,
            margin: 15,
            stagePadding: 30
        },
        1024: {
            items: 3,
            margin: 25,
            stagePadding: 30
        },
        1560: {
            items: 4,
            margin: 25,
            stagePadding: 50
        }
    }
}
class Residence extends React.Component {

    constructor() {
        super();
        this.state = {
            value: 'Details',
            button_no: 2,
            show: null
        };
        this.takeStep = this.takeStep.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
        if(id && id==='Estimate') {
            window.dataLayer.push({'event':'mortgageCalculator'});
        }
    }

    takeStep(param) {
        this.setState({ value: param });
    }
    componentDidMount() {
        $(".menu-bar").on('click', function () {
            $(".plan-overlay").slideToggle("slow");
            $(".menu-bar").toggleClass("active");
        });
        var WindowWidth = $(window).width();
        if (WindowWidth <= 1024) {
            $(window).on("scroll", function() {
                if($(window).scrollTop() > 0) {
                    $(".residence-header").addClass("overlay_back");
                } else {
                   $(".residence-header").removeClass("overlay_back");
                   
                }
            });
        }
        if( window.JQUERY('#residence-main-div').has('residence-main-div')){
            window.JQUERY('.header_section').hide();
            window.JQUERY('.main-outer-div').addClass('residence-top');
            window.JQUERY('.footer_section').hide();
            window.JQUERY('.top-scroll').hide();
            window.JQUERY('.top-scroll').removeClass('dynamic');
        }
        console.log('this is did mount');
    }
    componentDidUpdate(){
        window.JQUERY('.header_section').hide();
        window.JQUERY('.footer_section').hide();
        window.JQUERY('.top-scroll').hide();
        window.JQUERY('.top-scroll').removeClass('dynamic');
    }
    renderSwitch(param) {
        if(param && param==='Exteriors') {
            window.dataLayer.push({'event':'customizeExterior'});
        }
        if(param && param==='Floorplan') {
            window.dataLayer.push({'event':'customizeFloorplan'});
        }
        if(param && param==='Room_Visualizer') {
            window.dataLayer.push({'event':'customizeRoomVisualizer'});
        }
        switch (param) {
            case 'Details': return <ResidenceScreen1 residence_component={this.props.residence_component} />
            case 'Exteriors': return <ResidenceScreen2 residence_component={this.props.residence_component} />
            case 'Floorplan': return <ResidenceScreen3 residence_component={this.props.residence_component} />
            case 'Room_Visualizer': return <ResidenceScreen4 residence_component={this.props.residence_component} />
            case 'Summary': return <ResidenceScreen5 residence_component={this.props.residence_component} />
            case 'Save_Your_Home': return <ResidenceScreen6 residence_component={this.props.residence_component} />
        }
    }
    
    ButtonText(param,show_bdx_ifp,floorplan_images,activate_visualizer,visualizer_data,show_visualizer_bdx,bdx_visualizer_id) {
        var btn_floor_value , btn_room_value = '';

        if(floorplan_images || show_bdx_ifp){
            btn_floor_value = 'Next - Customize Floorplan';
        }else{
            if( activate_visualizer !== false && ( visualizer_data || (show_visualizer_bdx !== false && bdx_visualizer_id != '') ) ){
                btn_floor_value = 'Next - Room Visualizer';
            }else{
                btn_floor_value = 'Next - Summary';
            }
        } 
        if( activate_visualizer !== false && ( visualizer_data || (show_visualizer_bdx !== false && bdx_visualizer_id != '') ) ){
            btn_room_value = 'Next - Room Visualizer';
        }else{
            btn_room_value = 'Next - Summary';
        }
        switch (param) {
            case 'Details': return 'Build Your Home'
            case 'Exteriors': return btn_floor_value
            case 'Floorplan': return btn_room_value
            case 'Room_Visualizer': return 'Next - Summary'
            case 'Summary': return 'Save Your Home'
            case 'Save_Your_Home': return <FloorplanSave residence_component={this.props.residence_component} />
        }
    }

    renderPage(param,show_exterior,elevation_images,show_bdx_ifp,floorplan_images,activate_visualizer,visualizer_data,show_visualizer_bdx,bdx_visualizer_id){
        var detail_value ,floor_value , room_value = '';
      
        if(show_exterior !== false && elevation_images.length > 0){
            detail_value = 'Exteriors';
        }else{
            if(floorplan_images || show_bdx_ifp){
                detail_value = 'Floorplan';
            }else{
                if(activate_visualizer !== false && ( visualizer_data || (show_visualizer_bdx !== false && bdx_visualizer_id != '') )){
                    detail_value = 'Room_Visualizer';
                }else{
                    detail_value = 'Summary';
                }
            } 
        }
        if(floorplan_images || show_bdx_ifp){
            floor_value = 'Floorplan';
        }else{
            if( activate_visualizer !== false && ( visualizer_data || (show_visualizer_bdx !== false && bdx_visualizer_id != '') ) ){
                floor_value = 'Room_Visualizer';
            }else{
                floor_value = 'Summary';
            }
        } 
        if( activate_visualizer !== false && ( visualizer_data || (show_visualizer_bdx !== false && bdx_visualizer_id != '') ) ){
            room_value = 'Room_Visualizer';
        }else{
            room_value = 'Summary';
        }
       
        switch (param) {
            case 'Details': return this.setState({ value: detail_value });
            case 'Exteriors': return this.setState({ value: floor_value });
            case 'Floorplan': return this.setState({ value: room_value });
            case 'Room_Visualizer': return this.setState({ value: 'Summary' });
            case 'Summary': return this.setState({ value: 'Save_Your_Home' });
        } 
    
    }

    render() {
        var residence_component = this.props.residence_component;

        var show_bdx_ifp;
        
        if(residence_component.floorplans.show_floorplan_bdx == true && residence_component.floorplans.bdx_type != ''){
            if(residence_component.floorplans.bdx_type == 'ifp_plugin' && residence_component.floorplans.bdx_plan_id == ''){
                show_bdx_ifp = false;
            }
            else{
                show_bdx_ifp = true;
            }
        }
        else{
            show_bdx_ifp = false;
        }
        
        return (
            <div className="residence-main-div" id='residence-main-div'>
                <div className="residence-header">
                    <Link to={residence_component.common_detail.nbd_permalink} className="neigh-name">
                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                        {residence_component.common_detail.nbd_title ? (
                            <div className="neighborhood-name">{residence_component.common_detail.nbd_title}</div>
                        ) : ('')}
                    </Link>
                    <div className="residence-nav-bar">
                        <ul>
                            {this.state.value === 'Details' ? (
                                <li className="plan-link current-active" onClick={() => this.takeStep('Details')}><span>Details</span></li>
                            ) : (
                                <li className="plan-link" onClick={() => this.takeStep('Details')}><span>Details</span></li>
                            )}
                            {residence_component.exterior.show_exterior_tab !== false && residence_component.exterior.elevation_images.length > 0 ? (
                                this.state.value === 'Exteriors' ? (
                                    <li className="plan-link current-active" onClick={() => this.takeStep('Exteriors')}><span>Exteriors</span></li>
                                ) : (
                                    <li className="plan-link" onClick={() => this.takeStep('Exteriors')}><span>Exteriors</span></li>
                                )
                            ) : ('')}
                            {residence_component.floorplans.floorplan_images || show_bdx_ifp? (
                                this.state.value === 'Floorplan' ? ( 
                                    <li className="plan-link current-active" onClick={() => this.takeStep('Floorplan')}><span>Floorplan</span></li>
                                ):( 
                                    <li className="plan-link" onClick={() => this.takeStep('Floorplan')}><span>Floorplan</span></li>
                                )
                            ) : ('')}
                            {residence_component.room_visualizer.activate_visualizer !== false && ( residence_component.room_visualizer.visualizer_data || (residence_component.room_visualizer.show_visualizer_bdx !== false && residence_component.room_visualizer.bdx_visualizer_id != '') ) ? (
                                this.state.value === 'Room_Visualizer' ? ( 
                                    <li className="plan-link current-active" onClick={() => this.takeStep('Room_Visualizer')}><span>Room Visualizer</span></li>
                                ):( 
                                    <li className="plan-link" onClick={() => this.takeStep('Room_Visualizer')}><span>Room Visualizer</span></li>
                                )
                            ) : ('')}
                            {this.state.value === 'Summary' || this.state.value === 'Save_Your_Home' ? ( 
                                <li className="plan-link current-active" onClick={() => this.takeStep('Summary')}><span>Summary</span></li>
                            ):( 
                                <li className="plan-link" onClick={() => this.takeStep('Summary')}><span>Summary</span></li>
                            )}
                        </ul>
                    </div>
                    <div className="plan-icons">
                        <div className="residence-btn">
                            <a href="#" className="resisdence-save-btn" title="save">Save for Later</a>
                        </div>
                        <i className="fa fa-heart-o" aria-hidden="true"></i></div>
                </div>

                {this.renderSwitch(this.state.value)}

                <div className="plan-features">
                    <div className="plan-footer-left">
                        <div className="plan-details-container">
                            {residence_component.residence.length > 0 ? (
                                <div className="menu-bar">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            ) : ('')}
                            <div className="plan-details">
                                {residence_component.common_detail.res_title ? (
                                    <div className="residence-name">{residence_component.common_detail.res_title}</div>
                                ) : ('')}
                                <div className="plan-estimate-price">
                                    {residence_component.common_detail.price_value && residence_component.common_detail.post_status !== "Sold Out" && residence_component.common_detail.post_status !== "Temporarily Sold Out" ? (
                                        <div className="residence-price">{residence_component.common_detail.price_value}</div>
                                    ) : ('')}
                                    <a href="javascript:void(0)" title="Estimate" className="estimate-payment" onClick={() => this.handleShow("Estimate")}>Estimate Payment</a>
                                    <Modal className="Estimate_modal" show={this.state.show === "Estimate"} onHide={this.handleClose} >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <MortgageSection mortgage_data={residence_component.common_detail.mortgage_data}/>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <ul className="plan-features-list">
                            {residence_component.common_detail.square_feet ? (
                                <li>
                                    <span className="feature-value">{residence_component.common_detail.square_feet}</span>
                                    <span className="feature-name">Sq.{'\u00A0'}Footage</span>
                                </li>
                            ) : ('')}
                            {residence_component.common_detail.bedroom ? (
                                <li>
                                    <span className="feature-value">{residence_component.common_detail.bedroom}</span>
                                    <span className="feature-name">
                                        {residence_component.common_detail.bedroom === '1' ? ('Bedroom') : ('Bedrooms')}
                                    </span>
                                </li>
                            ) : ('')}
                            {residence_component.common_detail.bath ? (
                                <li>
                                    <span className="feature-value">{residence_component.common_detail.bath}</span>
                                    <span className="feature-name">
                                        {residence_component.common_detail.bath === '1' ? ('Bathroom') : ('Bathrooms')}
                                    </span>
                                </li>
                            ) : ('')}
                            {residence_component.common_detail.garage ? (
                                <li>
                                    <span className="feature-value">{residence_component.common_detail.garage}</span>
                                    <span className="feature-name">Bay{'\u00A0'}Garage</span>
                                </li>
                            ) : ('')}
                            {residence_component.common_detail.stories ? (
                                <li>
                                    <span className="feature-value">{residence_component.common_detail.stories}</span>
                                    <span className="feature-name">
                                        {residence_component.common_detail.stories === '1' ? ('Story') : ('Stories')}
                                    </span>
                                </li>
                            ) : ('')}
                        </ul>
                        {residence_component.residence.length > 0 ? (
                            <div className='plan-overlay'>

                                <OwlCarousel {...plan_options} className="owl-theme">
                                    {residence_component.residence.map((item,index) => (
                                        <div className="plan_card_slide">
                                            <img src={item.card_image[0]} alt={"New homes in "+item.card_image_title[index]} title={item.post_title} className="overlay-plan-image" />
                                            {item.post_title ? (
                                                <p className="overlay-plan-name">{item.post_title}</p>
                                            ) : ('')}
                                            {item.price_value && item.post_status !== "Sold Out" && item.post_status !== "Temporarily Sold Out" ? (<p>{item.price_value}</p>) : ('')}
                                            <Link to={item.post_permalink} className="learn-more">View Details</Link>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        ) : ('')}
                    </div>
                    <div className="plan-footer-right">
                        {/* <div className="build-your-home" onClick={() => this.ButtonNumber(this.state.button_no)}> {this.ButtonText(this.state.value)}</div> */}
                        <div className="build-your-home" onClick={() => this.renderPage(this.state.value,residence_component.exterior.show_exterior_tab,residence_component.exterior.elevation_images,show_bdx_ifp,residence_component.floorplans.floorplan_images,residence_component.room_visualizer.activate_visualizer,residence_component.room_visualizer.visualizer_data,residence_component.room_visualizer.show_visualizer_bdx,residence_component.room_visualizer.bdx_visualizer_id)}> {this.ButtonText(this.state.value,show_bdx_ifp,residence_component.floorplans.floorplan_images,residence_component.room_visualizer.activate_visualizer,residence_component.room_visualizer.visualizer_data,residence_component.room_visualizer.show_visualizer_bdx,residence_component.room_visualizer.bdx_visualizer_id)}</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Residence; 
