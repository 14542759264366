import React from 'react';
import './Residence.scss';
import $ from 'jquery'
import ScheduleTourForm from './ScheduleTourForm.jsx';
import TPCPriorityGroupForm from './TPCPriorityGroupForm.jsx';
import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import SidebarSignUp from './SidebarSignUp.jsx';
import { Modal } from 'react-bootstrap';
import MortgageSection from './MortgageSection';

import defaultImg from '../../assets/images/TPH-default-image2.gif';

class ResidenceScreen5 extends React.Component {
    constructor() {
        super();
        this.state = {
            show: null
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ show: null });
    }

    handleShow(id) {
        this.setState({ show: id });
        if(id && id==='Estimate') {
            window.dataLayer.push({'event':'mortgageCalculator'});
        }
    }
    componentDidMount() {
        $('.residence-header').removeClass('bdx-added');
    }
    render() {
        var residence_component = this.props.residence_component;
        var tpc_form_btn = residence_component.common_detail.tpc_priority_group_form && residence_component.common_detail.tpc_priority_group_form.show_btn;
        console.log(residence_component,defaultImg);
        return (
            <div className="residence-details" id="screen-5">
                <div className="residence-image"> 
                    <div className="image-column"> 
                    { residence_component.summary.summary_image ? (
                        <img src={residence_component.summary.summary_image.url} alt={residence_component.summary.summary_image.title} />
                    ):(
                        <img src={defaultImg} alt="defaultImg" title="defaultImg" />
                    ) }
                    </div>
                    <div className="detail-column"> 
                    

                            {(residence_component.summary.join_the_list_cta && (residence_component.summary.join_the_list_heading || residence_component.summary.join_the_list_description) ) ? (
                                <div className="detail-content">
                                    <div className="detail-text">
                                        <div className="detail-title">{residence_component.summary.join_the_list_heading}</div>
                                        <p className="detail-description">{residence_component.summary.join_the_list_description}</p>
                                    </div>
                                    <div className="residence-btn">
                                    <a href="javascript:void(0)" className="resisdence-save-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")}>{residence_component.summary.join_the_list_cta}</a>
                                </div>
                                <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body>
                                        <SidebarSignUp sidebar_data={residence_component.summary.sidebar_form} communityOfInterest={residence_component.common_detail.communityOfInterest} nbd_title={residence_component.common_detail.nbd_title} division_name={residence_component.common_detail.division_name}/>
                                    </Modal.Body>
                                </Modal>
                                </div>
                            ):('')}
                        
                           {(residence_component.summary.request_quote_cta && (residence_component.summary.request_quote_heading || residence_component.summary.request_quote_discription) && residence_component.summary.hide_quota_cta != true  )? (
                                <div className="detail-content">
                                    <div className="detail-text">
                                        <div className="detail-title">{residence_component.summary.request_quote_heading}</div>
                                        <p className="detail-description">{residence_component.summary.request_quote_discription}</p>
                                    </div>
                                    <div className="residence-btn">
                                        <a href="javascript:void(0)" className="resisdence-save-btn" title="Request Quote" onClick={() => this.handleShow("Request_quotes")}>{residence_component.summary.request_quote_cta}</a>
                                    </div>
                                    <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quotes"} onHide={this.handleClose} >
                                        <Modal.Header closeButton></Modal.Header>
                                        <Modal.Body>
                                            <SidebarSignUp sidebar_data={residence_component.summary.sidebar_form} communityOfInterest={residence_component.common_detail.communityOfInterest} nbd_title={residence_component.common_detail.nbd_title} division_name={residence_component.common_detail.division_name}/>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            ):('')}
                        
                        {(tpc_form_btn || residence_component.common_detail.tour_details.show_tour_btn ) ? (
                            <div className="detail-content">
                                <div className="detail-text">
                                    {(tpc_form_btn) ? (
                                        <React.Fragment>
                                            <div tabindex="0" className="detail-title">Join The Priority Group</div>
                                            {residence_component.summary.tpc_form_description && <p tabindex="0" className="detail-description">{residence_component.summary.tpc_form_description}</p>}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {residence_component.summary.schedule_heading && <div tabindex="0" className="detail-title">{residence_component.summary.schedule_heading}</div>}
                                            {residence_component.summary.schedule_discription && <p tabindex="0" className="detail-description">{residence_component.summary.schedule_discription}</p>}
                                        </React.Fragment>
                                    )}
    
                                </div>
                                <div className="residence-btn">
                                    {(tpc_form_btn) ?[
                            <React.Fragment>
                                 <a href="javascript:void(0)"  title="JOIN THE PRIORITY GROUP" data-fancybox="priority_group_form" data-src="#tour_form" className="interactive-btn" onClick={()=>{
                                    window.dataLayer.push({'event':'preQualificationFormClick'});
                                }}>JOIN THE PRIORITY GROUP</a>
                                 <TPCPriorityGroupForm tpc_form_details={residence_component.common_detail.tpc_priority_group_form} division_name={residence_component.common_detail.division_name} />
                            </React.Fragment>
                        ]:[
                        residence_component.common_detail.tour_details.show_tour_btn && residence_component.summary.schedule_cta && (residence_component.summary.schedule_heading || residence_component.summary.schedule_discription)  ? (
                            <div className="detail-content">
                                <div className="detail-text">
                                    <div className="detail-title">{residence_component.summary.schedule_heading}</div>
                                    <p className="detail-description">{residence_component.summary.schedule_discription}</p>
                                </div>
                                <div className="residence-btn">
                                    <a href="javascript:void(0)" title="Schedule a tour" data-fancybox="tour_form" data-src="#tour_form" onClick={() => {
                                        window.dataLayer.push({'event':'scheduleTourFormClick'});
                                    }}>{residence_component.summary.schedule_cta}</a>
                                    <ScheduleTourForm form_details={residence_component.common_detail.tour_details} division_name={residence_component.common_detail.division_name}/>
                                </div>
                            </div>
                         ):('')]}
                                </div>
                            </div>
                            ):('')}
                        {(residence_component.summary.get_prequalified_link && residence_component.summary.get_prequalified_cta) && (residence_component.summary.get_prequalified_heading || residence_component.summary.get_prequalified_description)  && residence_component.summary.hide_get_pre_qualified_cta != true  ? (
                            <div className="detail-content">
                                <div className="detail-text">
                                    <div className="detail-title">{residence_component.summary.get_prequalified_heading}</div>
                                    <p className="detail-description">{residence_component.summary.get_prequalified_description} </p>
                                </div>
                                <div className="residence-btn">
                                    <a href={residence_component.summary.get_prequalified_link} className="resisdence-save-btn" target="_blank" title="Get Pre-Qualified">{residence_component.summary.get_prequalified_cta}</a>
                                </div>
                            </div>
                        ):('')}
              
                    
                    </div>
                </div>
            </div>
        );
    }
}
export default ResidenceScreen5; 
