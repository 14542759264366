import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import Auth0ProviderWithHistory from "./app/auth/auth0-provider-with-history";
// Baseline styles
require('./assets/fonts/fonts.scss')
require('./assets/scss/App.scss')

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
        <App/>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
