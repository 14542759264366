import React from "react";
import $ from "jquery";
import "./Blogs.scss";
import { Link } from "react-router-dom";

class Blogs extends React.Component {
  state = {
    current_blog_post: this.props.updated_blog_post,
    selectedCat: {}
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updated_blog_post !== this.props.updated_blog_post) {
      this.setState({
        current_blog_post: this.props.updated_blog_post
      });
    }
  }
  componentDidMount() {
    const search_param = new URLSearchParams(window.location.search);
    const currentUrl = window.location.href;
    const lastParam = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);
    const blogId = currentUrl.substring(currentUrl.lastIndexOf("?") + 1);
    var existInArray = false;
    this.props.category_list.forEach(function(a) {
      const name  = a.slug;
      if(name == blogId){
        existInArray = true;
      }
    });
    if (
      search_param.has("category") ||
      lastParam == "blog" ||
      lastParam == "" ||
      lastParam == null || existInArray == false
    ) {
      const temp = search_param.has("category")
        ? this.props.category_list.filter(
            item => item.slug === search_param.get("category")
          )[0]
        : this.props.category_list[0];

      this.update_posts(temp);
      
    } else if (existInArray == true) {
      var link = document.getElementById(blogId);
      link.click();
      $('html, body').animate({
        scrollTop: $(".category_list").offset().top - 96
      }, 1000);
    }
  }

  update_posts = selected_cat => {
    const current_posts = this.props.updated_blog_post.filter((item, i) =>
      item.all_cat.includes(selected_cat.name)
    );
    this.setState({
      current_blog_post: current_posts,
      selectedCat: selected_cat
    });
  };
  handleCatClick = e => {
    this.update_posts(this.props.category_list[e.target.selectedIndex - 1]);
  };

  render() {
    const { category_list } = this.props;
    const { current_blog_post, selectedCat } = this.state;
    var toShow;
    var window_width = window.screen.width;

    return !current_blog_post || !current_blog_post.length ? null : (
      <div className="blog_listing_container blogs-section">
        {current_blog_post[0].post_thumbnail_url ? (
          <div className="blog-cover-section">
            <div className="blog-cover-content">
              {current_blog_post[0].post_thumbnail_url && (
                <div className="blog-cover-img divide-equal">
                  <Link to={`/blog/${current_blog_post[0].post_name}`}>
                    <img
                      src={current_blog_post[0].post_thumbnail_url}
                      title={current_blog_post[0].post_title}
                      alt={current_blog_post[0].post_title}
                    />
                  </Link>
                </div>
              )}
              <div className="blog-cover-info divide-equal">
                <div className="header-section">
                  <div className="heading-section">
                    {selectedCat.name && (
                      <p className="sub-title">
                        {selectedCat.name.replace("&amp;", "&")}
                      </p>
                    )}
                    {current_blog_post[0].post_title && (
                      <h2 className="main-title">
                        <span>{current_blog_post[0].post_title}</span>
                      </h2>
                    )}
                  </div>
                  {current_blog_post[0].summary ?<p tabindex="0" className="title-info" dangerouslySetInnerHTML={{ __html: current_blog_post[0].summary.replace(/(?:\r\n|\r|\n)/g, '') }}></p> : ''}
                  {/* {current_blog_post[0].post_content &&
                    ((toShow =
                      current_blog_post[0].post_content.substring(0, 150) +
                      "..."),
                    (
                      <p
                        className="title-info"
                        dangerouslySetInnerHTML={{
                          __html: toShow.replace(/(?:\r\n|\r|\n)/g, "")
                        }}
                      ></p>
                    ))} */}
                  {current_blog_post[0].post_name && (
                    <Link
                      to={`/blog/${current_blog_post[0].post_name}`}
                      className="read_more"
                    >
                      Read More
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {window_width > 991 ? (
          <div className="category_list">
            {category_list.map((item, i) => {
              const split_arr = item.name.replace("&amp;", "&").split(" & ");
              return (
                <div
                  id={item.slug}
                  className={`cat_list_item ${
                    selectedCat.slug === item.slug ? "active" : ""
                  }`}
                  key={i}
                  onClick={() => this.update_posts(item)}
                >
                  {split_arr[0]} {split_arr[1] && [<br />, `& ${split_arr[1]}`]}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {window_width <= 991 ? (
          <div className="category_list">
            <select
              name="category_dropdown_list"
              className="category_dropdown_list"
              onChange={this.handleCatClick}
            >
              <option value="BLOG CATEGORIES">BLOG CATEGORIES</option>
              {category_list.map((item, i) => {
                return (
                  <option
                    value={item.name.replace("&amp;", "&")}
                    selected={selectedCat.slug === item.slug}
                    className={`cat_list_item ${
                      selectedCat.slug === item.slug ? "active" : ""
                    }`}
                    key={i}
                  >
                    {item.name.replace("&amp;", "&")}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          ""
        )}
        {current_blog_post.length > 1 && (
          <div className="card_heading_content">
            <h4 className="brand_heading">Altis Blog</h4>
            <h2 className="latest_post_heading">The Latest</h2>
          </div>
        )}
        <div className="blog_card_container">
          {current_blog_post.slice(1).map((item, i) => {
            return (
              <div className="blog_card" key={i}>
                {item.post_thumbnail_url ? (
                  <Link to={`/blog/${item.post_name}`}>
                    <div className="blog_card_img">
                      <img
                        src={item.post_thumbnail_url}
                        title={item.post_title}
                        alt={item.post_title}
                      />
                    </div>
                  </Link>
                ) : (
                  ""
                )}
                <div className="blog_card_content">
                  {selectedCat.name ? (
                    <div className="blog_card_category">
                      {selectedCat.name.replace("&amp;", "&")}
                    </div>
                  ) : (
                    ""
                  )}
                  {item.post_title ? (
                    <div className="blog_card_content_caption">
                      <h4>{item.post_title}</h4>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.summary ?
                    <div className="blog_card_content_para">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.summary.replace(/(?:\r\n|\r|\n)/g, "")
                      }}
                    />
                  </div>
                  : ''}
                  {/* {item.post_content
                    ? ((toShow = item.post_content.substring(0, 150) + "..."),
                      (
                        <div className="blog_card_content_para">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: toShow.replace(/(?:\r\n|\r|\n)/g, "")
                            }}
                          />
                        </div>
                      ))
                    : ""} */}
                  {item.post_name ? (
                    <div className="read_more_btn">
                      <Link
                        to={`/blog/${item.post_name}`}
                        className="read_more"
                      >
                        Read More
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Blogs;
