import React from "react";
import "./Header.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiConfig } from "../Components/Helper.jsx";
import LoginButton from '../Components/LoginButton.jsx';
import logo from "../../assets/images/logo.png";
import home from "../../assets/images/home.jpg";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false,
      header_info: [],
      success: false
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (window.scrollY > 128) {
      document.body.classList.add("sticky-header");
    } else {
      document.body.classList.remove("sticky-header");
    }
  };

  getChildItems = () => {
    for (let i = 0; i < this.state.header_info.header_menu.length; i++) {
      if (this.state.header_info.header_menu[i].menu_item_parent !== "0") {
        for (let k = 0; k < this.state.header_info.header_menu.length; k++) {
          if (
            this.state.header_info.header_menu[i].menu_item_parent ==
            this.state.header_info.header_menu[k].ID
          ) {
            this.state.header_info.header_menu[k].child_id.push(
              this.state.header_info.header_menu[i]
            );
          }
        }
      }
    }
    this.state.header_info.header_menu = this.state.header_info.header_menu.filter(
      i => i.menu_item_parent == "0"
    );
  };

  componentDidMount() {
    axios.get(apiConfig().API_URL + "/header_info").then(response => {
      this.setState({
        header_info: response.data.data,
        success: response.data.success
      });
      this.getChildItems();
    });
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
    
    if(window.location.href.indexOf("wpengine") > -1) {
      window.dataLayer.push({
          'event':'environment',
          'staging_production_flag':'Staging'
      });
  } else {
      window.dataLayer.push({
          'event':'environment',
          'staging_production_flag':'Production'
      });
  }
  window.CallTrk.swap();   
  }
  componentDidUpdate() {
    window.CallTrk.swap();   
  }
  toggleMenu() {
    if (this.state.active) {
      this.setState({ active: false });
      document.body.classList.remove("body_overflow");
    } else {
      this.setState({ active: true });
      document.body.classList.add("body_overflow");
    }
  }

  showHome() {
    this.setState({ active: false });
    document.body.classList.remove("body_overflow");
  }

  render() {
    var success = this.state.success;
    var header_info = this.state.header_info;
    //console.log(header_info);
    return (
      <>
        {success === true ? (
          <header className ="header_section">
            <div className="container">
              <div className="headerlogoarea">
                <div
                  className={
                    this.state.active ? "hamburgerBtn active" : "hamburgerBtn"
                  }
                  onClick={() => this.toggleMenu()}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span className="text">MENU</span>
                </div>
                <div className="logoWrapper">
                  <Link to={"/"} onClick={() => this.showHome()}>
                    <img className="headerLogo" src={logo} alt="Altis Logo"  title="Altis Logo" />
                  </Link>
                </div>
              </div>

              <div
                className={
                  this.state.active ? "menu-items" : "hidden menu-items"
                }
              >
                <div className="menu-listing">
                  <div className="primary-menu">
                    <ul>
                      {header_info.header_menu.map(items => (
                        <li>
                          <Link
                            to={items.url}
                            title={items.title}
                            onClick={() => this.toggleMenu()}
                          >
                            {items.title}
                          </Link>
                          <ul>
                            {items.child_id.map(child => (
                              <li>
                                <Link
                                  to={child.url}
                                  title={child.title}
                                  onClick={() => this.toggleMenu()}
                                >
                                  {child.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                      <LoginButton/>
                      <li className="btn">
                        <Link
                          to='/contact-us/'
                          title="Contact Us"
                          className="tour-schedule"
                          onClick={() => this.toggleMenu()}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="latest-home-listing">
                  <div className="heading">
                    <h2>Find your home</h2>
                  </div>
                  <div className="highlighted-home">
                    {header_info.card_data.map(items => (
                      <div className="home">
                        <Link
                          to={items.page_url}
                          onClick={() => this.toggleMenu()}
                        >
                          <img alt={items.card_name} title={items.card_name} src={items.page_thumb_image} />
                          <h2>
                            {items.card_name}
                            <span>
                              {items.card_city}, {items.card_state}
                            </span>
                          </h2>
                          <span className="tagline">{items.card_price}</span>
                          <div class="arrowRight"></div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </header>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default Header;
