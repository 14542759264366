import React from 'react';
import './SidebarForm.scss';
import { Modal } from 'react-bootstrap';
import SidebarSignUp from './SidebarSignUp.jsx';
import ScheduleTourForm from './ScheduleTourForm.jsx';

class SidebarForm extends React.Component {  

     constructor(props) {
          super(props);
          this.state = {
               show: null
           };
           this.handleShow = this.handleShow.bind(this);
           this.handleClose = this.handleClose.bind(this);
          //  this.load_sfdc_chat = this.load_sfdc_chat.bind(this)
          
      }
      handleClose() {
          this.setState({ show: null });
      }
  
      handleShow(id) {
          this.setState({ show: id });
      }
     componentDidMount() {
          if(window.location.href.indexOf("#interest-form") > -1) {
              document.getElementById("interest_click_btn").click();
          }
      }
     calltoaction_data_layer_push(param) {
        this.setState({ value:param })
        if(param && param==='clickToCall') {
            window.dataLayer.push({'event':'clickToCall'});
        }
     }
  render() {    
     var agent_info = this.props.agent_info; 
     var window_width = window.screen.width;
     var posttype = this.props.posttype; 
     var community_data = this.props.community_data; 
     var post_status = this.props.post_status; 
     var tour_btn = this.props.tour_btn.show_tour_btn;
 
     return (  
          <div className="sidebar-form">  
               <div className="sidebar-close mobile-close"></div>
               <div className="sidebar-agent-info">
                    {agent_info.agent_nhs_heading ? (
                         <p className="sidebar-title">{agent_info.agent_nhs_heading}</p>
                    ):(
                         <p className="sidebar-title">New Home Specialist</p>
                    )}
                    {agent_info.agents_contact_name ? (
                         <p className="agent-name">{agent_info.agents_contact_name} {agent_info.agent_heading}</p>
                    ):('')}
                    <div className="sidebar-btn">
                         {(window_width < 767) ? [
                              posttype === 'qmi' ?[
                                   tour_btn  ? [
                                        <a href="javascript:void(0)" data-fancybox-trigger="tour_forms"
                                        className="filled-btn">{this.props.schedule_button_text}</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):('')
                                   ]:[
                                        <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Join The Interest List</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):(''),
                                        <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                             <Modal.Header closeButton></Modal.Header>
                                             <Modal.Body>
                                                  <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.neigh_title} division_name={community_data.division_name}/>
                                             </Modal.Body>
                                        </Modal>
                                   ]
                              ]:[ 
                                   tour_btn  ? [
                                        <a href="javascript:void(0)" className="filled-btn" 
                                        data-fancybox-trigger="tour_form" title="schedule tour" onClick={() => {this.handleShow("schedule_tour"); window.dataLayer.push({'event':'scheduleTourFormClick'})}}
                                        >Schedule a Tour</a>,
                                        <Modal className="Estimate_modal Request_quote tph-ui-modal" show={this.state.show === "schedule_tour"} onHide={this.handleClose} >
                                             <Modal.Header closeButton></Modal.Header>
                                             <Modal.Body>
                                             <ScheduleTourForm form_details={community_data.tour_details} division_name={community_data.division_name} />
                                             </Modal.Body>
                                        </Modal>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):('')
                                   ]:[
                                        <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" id="interest_click_btn" onClick={() => this.handleShow("Request_quote")}>Join The Interest List</a>,
                                        agent_info.agents_contact_phone ? (
                                             <p className="small-gray-text">--or--</p>
                                        ):(''),
                                        <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                             <Modal.Header closeButton></Modal.Header>
                                             <Modal.Body>
                                                  <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}/>
                                             </Modal.Body>
                                        </Modal>
                                   ]
                              ]
                         ]:[
                              <a href="javascript:void(0)" className="filled-btn" title="Join The Interest List" onClick={() => this.handleShow("Request_quote")} id="interest_click_btn">Join The Interest List</a>,
                              agent_info.agents_contact_phone ? (
                                   <p className="small-gray-text">--or--</p>
                              ):(''),
                              <Modal className="Estimate_modal Request_quote" show={this.state.show === "Request_quote"} onHide={this.handleClose} >
                                   <Modal.Header closeButton></Modal.Header>
                                   <Modal.Body>
                                   {posttype === 'qmi' ? (
                                        <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.neigh_title} division_name={community_data.division_name}/>
                                   ):(
                                        <SidebarSignUp sidebar_data={community_data.sidebar_form} communityOfInterest={community_data.communityOfInterest} nbd_title={community_data.title} division_name={community_data.division_name}/>
                                   )}
                                   </Modal.Body>
                              </Modal>
                         ]}
                     
                         {agent_info.agents_contact_phone ? (
                              <a href={"tel:"+agent_info.agents_contact_phone} className="phone-num" title="phone number" onClick={() => this.calltoaction_data_layer_push('clickToCall')}>{agent_info.agents_contact_phone}</a>
                         ):('')}
                    </div>
                    {agent_info.dre_license ? (
                         <p className="small-gray-text dre-num">{agent_info.dre_license}</p>
                    ):('')}
               </div>
          </div>  
     );  
  }  
}  

export default SidebarForm; 
