import React from 'react';
import './ScheduleTour.scss';

import HeadingSection from './HeadingSection';
import AgentForm from './AgentForm';
import { Modal } from 'react-bootstrap';
class BrokersFile extends React.Component {  
    constructor(props) {
        super(props);
        this.state ={
            show : null,
          }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);      
    }
  
    handleClose() {
        this.setState({ show: null });
    }
  
    handleShow(id) {
        this.setState({ show: id });
    }
  render() {  
    var brokers_details = this.props.brokers_details;
    
    var backgroundImage = brokers_details.main_image.url;
    if(backgroundImage){
        var background_image = {
            backgroundImage:'url('+backgroundImage+')',
        }
    }
     return ( 
         
        brokers_details.pattern_image.url && brokers_details.headline && brokers_details.description ? (
        <div className="schedule-tour BrokerBtn">
            <div className="two-image">
                <div className="image-section" style={background_image}>
                    {brokers_details.pattern_image ? (
                        <div className="image-building">
                            <img src={brokers_details.pattern_image.url}  className="image-building-image" title={brokers_details.pattern_image.title} alt={brokers_details.pattern_image.title}/>
                        </div>
                    ):('')}
                </div>
            </div>
            <div className="content-section">
                <HeadingSection 
                subtitle={brokers_details.sub_text} 
                title_part_1={brokers_details.headline}    
                title_part_2={brokers_details.headline_part_2}  
                headline_part_1_color = {brokers_details.headline_part_1_color}  
                headline_part_2_color = {brokers_details.headline_part_2_color}  
                />
                {brokers_details.description ? (
                    <p className="description" dangerouslySetInnerHTML={{__html:brokers_details.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                ):('')}
                <div className="Broker-Btns">
                {brokers_details.broker_data && brokers_details.cta_label ? (
                        <a href="javascript:void(0)" className="tour-schedule" title={brokers_details.cta_label} onClick={() => this.handleShow("Review_Policy")}>{brokers_details.cta_label}</a>
                ):('')}
                {brokers_details.brokerbtn ? (
                    <a href="javascript:void(0)" className="filled-btn" title={brokers_details.brokerbtn} onClick={() => this.handleShow("Broker_modal")}>{brokers_details.brokerbtn}</a>
                ):(
                    <a href="javascript:void(0)" className="filled-btn" title="Click Here" onClick={() => this.handleShow("Broker_modal")}>Click Here</a>
                )}
            </div>
            </div>
            <Modal className="Estimate_modal Request_quote" show={this.state.show === "Broker_modal"} onHide={this.handleClose} >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <AgentForm/>
                    </Modal.Body>
                </Modal>
                <Modal  className="Estimate_modal Review_Policy_main_div"  show={this.state.show === "Review_Policy"} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                    {/* <Modal.Title>  
                    </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                    {brokers_details.broker_data  ? (
                        <div className="Review_Policy_div" dangerouslySetInnerHTML={{__html:brokers_details.broker_data.replace(/(?:\r\n|\r|\n)/g, '')}}></div>   
                    ):('')}
                    </Modal.Body>
                </Modal>
        </div>
        ):('')
      );  
    }  
  }  
  
  export default BrokersFile; 