import React from 'react';
import {Link} from 'react-router-dom'
import './NeighCard.scss';
import {Modal } from 'react-bootstrap'
import HeadingSection from './HeadingSection';
import OwlCarousel from 'react-owl-carousel';  
import SectionTitle from './SectionTitle';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

const neigh_options = {
    loop : true,
    // margin:10,
    items: 1,
    dots: false,
    nav:true,
}
class NeighCard extends React.Component {  
    constructor() {
        super();
    
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    
        this.state = {
          show: null
        };
    }
    
      handleClose() {
        this.setState({show: null});
      }
    
      handleShow(id) {
        this.setState({show: id});
      }
      componentDidMount() {
        const section_id = this.props.id && this.props.id.trim();
        if (section_id) {
          setTimeout(function () {
              if (window.location.href.indexOf("#" + section_id) > -1) {
                  document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
              }
          }, 2000)
        }
      }
  render() { 
    var neigh_detail = this.props.neigh_detail; 
    var heading_data = this.props.heading_data; 
    var comm_price_value = heading_data.price_value && heading_data.community_status !=='Sold Out' ? ( heading_data.price_value):('');
    var toShow;
    const section_id = this.props.id && this.props.id.trim();
     return ( 
        neigh_detail.length > 0 ? [ 
            <div className="neigh-card-section" id={section_id}>   
                <SectionTitle  subtext='neighborhoods' headline='Find Your Home' />
                <div className="neigh-card">
                    {neigh_detail.map((items,key) => [
                    <div className="card-main-div">
                        <div className="card-imgs">
                        <div className="plan-img">
                        {items.card_image.length > 0 ? (
                            items.card_image.length === 1 ? (
                                <div className="card-img-div">
                                    <img src={items.card_image[0]} alt={items.card_image_title[0]?items.card_image_title[0]:items.card_image_title[1]} title={items.card_image_title[1]}/>
                                    {/* <p className="heart-img"><img src={require('../../assets/images/heart.svg')} alt="map" className="heart"/></p> */}
                                </div>   
                             ):(
                                <OwlCarousel {...neigh_options} className="owl-theme">  
                                  {items.card_image.map((item,index) => (
                                    <div className="card-img-div">
                                        <img src={item}  alt={"New homes in "+items.card_image_title[index]} title={items.post_title}/>
                                        {/* <p className="heart-img"><img src={require('../../assets/images/heart.svg')} alt="map" className="heart"/></p> */}
                                    </div>   
                                    ))}  
                                </OwlCarousel> 
                            )
                        ):('')}
                        </div>
                        <div className="plan-img-info">
                                {items.promotion_info ? ( 
                                    items.promotion_info.promo_snipe ? (
                                        <a href={items.promotion_info.promo_link}><span className="card-snipe">{items.promotion_info.promo_snipe}</span></a>
                                    ):(
                                        items.promotion_info.promo_icon ? (
                                            <a href={items.promotion_info.promo_link}><span className="promo-icon"><img src={items.promotion_info.promo_icon} alt="promo_icon" title="promo_icon" /></span></a>
                                        ):('')
                                    )
                                ):('')}
                                {/* <div className="card-img-info">
                                    {items.post_title ? (
                                        <h4 className="card-name">{items.post_title}</h4>
                                    ):('')}
                                    {items.price_value ? (
                                        <p className="card-price">{items.price_value}</p>
                                    ):('')}
                                </div> */}
                            </div>
                        </div>
                        <div className="card-info">
                            <div className="card-status-info">
                                {items.post_status ? (
                                    <p className="card-status">{items.post_status}</p>
                                ):('')}
                                {items.virtual_tour === 1 ? (
                                    <a href="javascript:void(0);" onClick={() => this.handleShow(key)} className="tour-img" title="virtual tour"><img src={require('../../assets/images/virtual-tour.svg').default} alt="virtual tour" title="virtual tour" className="virtual-tour"/>Virtual Tour</a>
                                ):('')}
                           
                            </div>
                            {items.post_title ? (
                                   <h2 className="card-name">{items.post_title}</h2>
                            ):('')}
                            {items.price_value ? (
                                    <p className="card-price">{items.price_value}</p>
                            ):('')}
                           
                            <ul className="card-detail">
                                {items.square_feet ? (
                                    <li>
                                    <span className="ruler card-icon">{items.square_feet}</span>
                                    <span className="feature-name">SQ. FOOTAGE</span>
                                    </li>
                                ):('')}
                                {items.bed ? (
                                    <li>
                                        <span className="bed card-icon">{items.bed}</span>
                                        <span className="feature-name">{items.bed === '1' ? (' Bed'):(' Beds')}</span>
                                    </li>
                                ):('')}
                                {items.bath ? (
                                    <li>
                                    <span className="bath card-icon">{items.bath}</span>
                                    <span className="feature-name">{items.bath === '1' ? (' Bath'):(' Baths')}</span>
                                    </li>
                                ):('')}
                                {items.garage ? (
                                    <li>
                                        <span className="garage card-icon">{items.garage}</span>
                                        <span className="feature-name">Garage</span>
                                    </li>
                                ):('')}
                            </ul>
                            {items.neighborhood_sub_text ? (
                                toShow = items.neighborhood_sub_text.substring(0,150)+"...",
                                <p className="card-text" dangerouslySetInnerHTML={{__html:toShow.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                            ):('')}
							<Link to={items.post_permalink} className="learn-more">Learn More</Link>
                        </div>
                    </div>,
                         items.virtual_tour === 1 ? (
                            <Modal className="virtual_modal" show={this.state.show === key} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>  
                                        <h2>{items.virtual_tour_title}</h2>
                                        <p>{items.virtual_tour_sub_title}</p>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        {items.virtual_tour_url ? (
                                            <iframe src={items.virtual_tour_url} className="virtual_iframe" title="virtual iframe"></iframe>
                                        ):('')}
                                </Modal.Body>
                            </Modal>
                        ):('')
                        ])}
                </div>
            </div>
        ]:['']
     );  
  }  
}  

export default NeighCard;