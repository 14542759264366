import React from 'react';
import axios from 'axios';
import { apiConfig } from '../../Components/Helper.jsx';
import { Link, Events } from 'react-scroll';
import EventRsvp from '../../Components/EventRsvp.jsx';
import moment from 'moment';
import TPHLoader from '../../Components/TPHLoader.jsx';
import $ from 'jquery';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import DocumentMeta from 'react-document-meta';

class EventDetailsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            event_info: '',
            success: false
        };
    }
     /*event structure data*/
     event_structured_data(event_info){

        if(event_info && event_info.event_metas && event_info.event_metas.event_address_time) {
            if( event_info.event_metas.event_address_time[0].city){
                var addressLocality = event_info.event_metas.event_address_time[0].city;
            }
            if(event_info.event_metas.event_address_time[0].state_abbreviation) {
                var addressRegion = event_info.event_metas.event_address_time[0].state_abbreviation;
            }
            if(event_info.event_metas.event_address_time[0].state_abbreviation){
                var postalCode = event_info.event_metas.event_address_time[0].zip_code;
            }
            if(event_info.event_metas.event_address_time[0].physical_address_heading){
               var streetAddress =event_info.event_metas.event_address_time[0].physical_address_heading;
            }
        }
        if(event_info && event_info.event_metas && event_info.event_metas.event_start_date) {
            var startDate = event_info.event_metas.event_start_date;
        }
        if(event_info && event_info.event_metas && event_info.event_metas.event_end_date){
            var endDate = event_info.event_metas.event_end_date;
        }
        if(event_info.post_title){
            var name = event_info.post_title;
        }



    const script = document.createElement("script");
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
        "@context": "https://schema.org",
        "@type": "Event",
        "location": {
        "@type": "Place",
        "address": {
        "@type": "PostalAddress",
        "addressLocality": "${addressLocality}",
        "addressRegion": "${addressRegion}",
        "postalCode": "${postalCode}",
        "streetAddress": "${streetAddress}"
        }
        },
        "name": "${name}",
        "startDate": "${startDate}",
        "endDate": "${endDate}"
        }`
      //  Structure Data script end 
      document.head.appendChild(script);

  }

    componentDidMount() {
        axios.get(apiConfig().API_URL + '/event/' + this.props.match.params.slug)
            .then(response => {
                this.setState({
                    event_info: response.data.data,
                    success: response.data.success,
                    message: response.data.message
                })
            });
 
        if (document.getElementsByClassName('rsvp-section').length > 0) {
            $('html, body').animate({
                scrollTop: $(".rsvp-section").offset().top
            }, 1000);
        }
        window.CallTrk.swap();
    }
    componentDidUpdate(){
        window.CallTrk.swap();
      }
    render() {
        var success = this.state.success;
        var event_info = this.state.event_info;
        var message = this.state.message;
        if (event_info && event_info.page_title) {
            var page_title = event_info.page_title;
          }
          if (event_info && event_info.page_desc) {
            var page_desc = event_info.page_desc;
          }
        const meta = {
            title: page_title,
            description: page_desc,
            canonical: window.location.href
          };
          if(event_info){
            this.event_structured_data(event_info);
          }
        return (
            <>
            <DocumentMeta {...meta} />
            {
                success === true ? [
                    <div className="events-detail">
                        <div className="container">

                            <div className="event_main_page_link">
                                <a href="/event">Back to Events</a> 
                            </div>

                            <div className="row detail-info">
                                <div className="img">
                                {event_info.event_metas.event_gallery[0] ? (
                                    event_info.event_metas.event_gallery[0].image ? (
                                    <img src={event_info.event_metas.event_gallery[0].image.url} title={event_info.event_metas.event_gallery[0].image.title} alt={event_info.event_metas.event_gallery[0].image.title} />
                                    ) : (
                                        <img src={require('../../../assets/images/TPH-default-image2.gif').default} title='event image' alt='event image' />
                                    )
                                ) : ('')}
                                    <div className="ev-date">
                                        <span>{moment(event_info.startDate.year + '-' + event_info.startDate.monthNum + '-' + event_info.startDate.day + ' 00:00:00','YYYY-M-D HH:mm:ss').format('dddd')}</span>
                                        <span>{event_info.startDate.day}</span>
                                    </div>
                                </div>

                                <div className="ev-detail">
                                    {event_info.event_metas.categories[0] ? (
                                        <span>{event_info.event_metas.categories[0].post_title}</span>
                                    ) : ('')}
                                    {event_info.post_title ? (
                                        <h3>{event_info.post_title}</h3>
                                    ) : ('')}
                                    {event_info.post_content ? (
                                        <p dangerouslySetInnerHTML={{ __html: event_info.post_content.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                    ) : ('')}
                                    <div className="rsvp-link">
                                        {
                                            event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Future Homeowner Event" || event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Resident Event" ? (
                                                <Link className="rsvp-nav-link" to="rsvp-section" title="RSVP TO ATTEND" duration={750} smooth={true} spy={true}>RSVP TO ATTEND</Link>
                                            ) : ('')
                                        }
                                    </div>
                                </div>
                            </div>

                            {event_info.event_metas.author_info ? (
                                event_info.event_metas.author_info.length > 0 ? (
                                    event_info.event_metas.author_info[0].author_position || event_info.event_metas.author_info[0].author_name || event_info.event_metas.author_info[0].author_detail ? (
                                    <div className="row author-box">
                                        <div className="author-detail">
                                            <div className="author-img">
                                                {event_info.event_metas.author_info[0].author_image ? (
                                                    <img src={event_info.event_metas.author_info[0].author_image.url} title={event_info.event_metas.author_info[0].author_image.title} alt={event_info.event_metas.author_info[0].author_image.title} />
                                                ) : ('')}
                                            </div>
                                            <div className="author-info">
                                                {event_info.event_metas.author_info[0].author_position ? (
                                                    <span>{event_info.event_metas.author_info[0].author_position}</span>
                                                ) : ('')}
                                                {event_info.event_metas.author_info[0].author_name ? (
                                                    <h3>{event_info.event_metas.author_info[0].author_name}</h3>
                                                ) : ('')}
                                                {event_info.event_metas.author_info[0].author_detail ? (
                                                    <div dangerouslySetInnerHTML={{ __html: event_info.event_metas.author_info[0].author_detail.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                                                ) : ('')}
                                                {event_info.event_metas.author_info[0].button_label && event_info.event_metas.author_info[0].button_label ? (
                                                    <a href={event_info.event_metas.author_info[0].button_label} title={event_info.event_metas.author_info[0].button_label}>{event_info.event_metas.author_info[0].button_label}</a>
                                                ) : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    ) : ('')
                                ) : ('')
                            ) : ('')}

                            <div className="row rsvp-form" id="rsvp-section">
                                {((event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Future Homeowner Event") || (event_info.event_metas.rsvp_options && event_info.event_metas.rsvp_options[0].show_rsvp && event_info.event_metas.event_type == "Resident Event")) ? (
                                    <EventRsvp event={event_info} />
                                ) : ('')}
                            </div>
                        </div>
                    </div>
                ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]
            }
            </>
        )
    }
}

export default EventDetailsPage;