import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import {apiConfig} from '../../Components/Helper.jsx';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import Blogs from '../../Components/Blogs.jsx';
import GlobalField from '../../Components/GlobalField';
import TPHLoader from '../../Components/TPHLoader.jsx';
import DocumentMeta from 'react-document-meta';
class BlogListing extends React.Component {  
  state ={
    bloglisting_data : [],
    success : false,
    message : '',
  }

  fetch_data(){
    axios.get(apiConfig().API_URL+'/blog_listing')
    .then(response => {
      this.setState({      
        bloglisting_data : response.data.data,
        success: response.data.success,
        message : response.data.message,
      })
      console.log(response);
    })
  }

  componentDidMount(){
    this.fetch_data();
    window.CallTrk.swap();   
    window.scrollTo(0,0);
  }
  componentDidUpdate() {
    window.CallTrk.swap();  
  }

  render() {    
    var success = this.state.success;
    var message = this.state.message; 
    var bloglisting_data = this.state.bloglisting_data;
    const meta = {
      title: bloglisting_data.page_title,
      description: bloglisting_data.page_desc,
      canonical: window.location.href
    };
    const category_list = [];
    const updated_blog_post =  bloglisting_data && bloglisting_data.blog_posts &&  bloglisting_data.blog_posts.map((item,i)=>{
      const all_cat = item.category.map((obj,index)=>{
        if(!category_list.filter(list_item=>list_item.slug===obj.slug).length){
      category_list.push({name:obj.name,slug:obj.slug});
        }
        return obj.name
      });
      return {...item,all_cat};
    })
    return (  
        <>  
        <DocumentMeta {...meta} />
            {success === true ? [ 
              <GlobalField global_data={bloglisting_data.component} />,
                <Blogs updated_blog_post={updated_blog_post} category_list={category_list}/>
              ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]} 
          
        </>
       ); 
       
  }  
}  

export default BlogListing; 