import React from 'react'

class Redirection extends React.Component {
  render () {
    return (
      <>
        <div className="page_not">
          redirecting...
        </div>
      </>
     
    )
  }
}

export default Redirection
