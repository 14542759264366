import React from "react";
import "./PlanCard.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HeadingSection from "./HeadingSection";
import SectionTitle from "./SectionTitle";

import planImg from "../../assets/images/plans.jpg";

const plan_options = {
  loop: true,
  items: 1,
  nav: true,
  dots: true,
  margin: 0,
  autoplay: true
};
class PlanCard extends React.Component {
  constructor() {
    super();

    this.state = {
      visible: 6
    };
    this.loadMore = this.loadMore.bind(this);
  }
  componentDidMount() {
    if (window.screen.width > 1024) {
      this.load_card_height();
    }
    const section_id = this.props.section_id && this.props.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }
  componentDidUpdate() {
    if (window.screen.width > 1024) {
      this.load_card_height();
    }
  }
  load_card_height() {
    var card_content_height = [];

    var plan_card_div = $(document).find(".plan-card .card-main-div");
    plan_card_div.each(function() {
      card_content_height.push(
        $(this)
          .find(".card-hover-content")
          .outerHeight()
      );
    });
    var card_height = $(plan_card_div[0]).outerHeight();
    var card_content_maxheight = Math.max.apply(Math, card_content_height);
    var image_height = card_height - card_content_maxheight;
    $(".plan-card .card-main-div").mouseenter(function() {
      var card_hover_content_div = $(this).find(".card-hover-content");
      var style_object = {
        height: "0px",
        visibility: "visible"
      };
      $(this)
        .find(".card-imgs")
        .animate({ height: image_height }, 300);
      card_hover_content_div.css(style_object);
      card_hover_content_div.animate({ height: card_content_maxheight }, 300);
      var card_info_div = $(this).find(".card-info");
      card_info_div.css("display", "none");
    });
    $(".plan-card .card-main-div").mouseleave(function() {
      var self = this;
      setTimeout(function() {
        $(self)
          .find(".card-hover-content")
          .css("visibility", "hidden");
        $(self)
          .find(".card-info")
          .css("visibility", "visible");
      }, 100);
      var card_info_div = $(this).find(".card-info");
      card_info_div.css("display", "block");
      card_info_div.css("visibility", "hidden");
      var card_info_height = card_info_div.innerHeight();
      $(this)
        .find(".card-imgs")
        .animate({ height: card_height - card_info_height }, 300);
    });
  }
  loadMore() {
    this.setState(prev => {
      return { visible: prev.visible + 6 };
    });
  }
  render() {
    var plan_detail = this.props.plan_detail;
    var sectiondata = this.props.sectiondata;
    var data_name = this.props.data_name;
    var window_width = window.screen.width;
    var toShow;
    const section_id = this.props.section_id && this.props.section_id.trim();
    return plan_detail.length > 0
      ? [
          <div className="residence-card-section" id="residence-card-section">
            <div className="container" id={section_id}>
              {data_name == 'global' ? (
                <SectionTitle subtext={sectiondata.sub_text} headline={sectiondata.headline} />
              ):(
                <SectionTitle subtext="Home Designs" headline="Find your home" />
              )}
             
              <div className="plan-card">
                {plan_detail.slice(0, this.state.visible).map(items => (
                  <div className="card-main-div col-lg-4 col-sm-6">
                    <div className="card-imgs">
                      <div className="plan-img">
                        {items.card_image.length > 0 ? (
                          items.card_image.length === 1 ? (
                            <Link to={items.post_permalink}>
                              <div className="overlay">
                                <img
                                  src={items.card_image[0]}
                                  className="image-building-image"
                                  alt={
                                    items.card_image_title[0]?items.card_image_title[0]:items.card_image_title[1]
                                  }
                                  title={items.card_image_title[1]}
                                />
                              </div>
                            </Link>
                          ) : (
                            <OwlCarousel
                              {...plan_options}
                              className="owl-theme"
                            >
                              {items.card_image.map((item, index) => (
                                <Link to={items.post_permalink}>
                                  <div className="overlay">
                                    <img
                                      src={item}
                                      className="image-building-image"
                                      alt={
                                        "New homes in " +
                                        items.card_image_title[index]
                                      }
                                      title={items.post_title}
                                    />
                                  </div>
                                </Link>
                              ))}
                            </OwlCarousel>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="plan-img-info">
                        {/* <span className="card-snipe">Promotion Snipe Here</span> */}

                        <div className="card-img-info">
                          {items.post_status ? (
                            items.post_status === "Temporarily Sold Out" ? (
                              <span className={"card-status status_gray"}>
                                {items.post_status}
                              </span>
                            ) : (
                              <span className="card-status">
                                {items.post_status}
                              </span>
                            )
                          ) : (
                            ""
                          )}
                          {items.post_title ? (
                            <h4 className="card-name">{items.post_title}</h4>
                          ) : (
                            ""
                          )}
                          {items.price_value &&
                          items.post_status !== "Sold Out" &&
                          items.post_status !== "Temporarily Sold Out" ? (
                            <p className="card-price">{items.price_value}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-info">
                      <ul className="card-detail">
                        {items.square_feet ? (
                          <li>
                            <span className="info-value">
                              {items.square_feet}
                            </span>
                            <span className="info-name">
                              Sq.{"\u00A0"}Footage
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        {items.bed ? (
                          <li>
                            <span className="info-value">{items.bed}</span>
                            <span className="info-name">
                              {items.bed === "1" ? "Bed" : "Beds"}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        {items.bath ? (
                          <li>
                            <span className="info-value">{items.bath}</span>
                            <span className="info-name">
                              {items.bath === "1" ? "Bath" : "Baths"}
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        {items.garage ? (
                          <li>
                            <span className="info-value">{items.garage}</span>
                            <span className="info-name">Garage</span>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                      {/* <Link to={items.post_permalink} className="learn-more">View Details</Link> */}
                    </div>
                    <div className="card-hover-content">
                      <div class="plan-card-content">
                        {items.post_title ? (
                          <h4 className="card-name">{items.post_title}</h4>
                        ) : (
                          ""
                        )}
                        {/*  <div class="plan-text">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</div> */}
                        {items.long_description
                          ? ((toShow =
                              items.long_description.substring(0, 150) + "..."),
                            (
                              <div
                                className="card-text"
                                dangerouslySetInnerHTML={{
                                  __html: toShow.replace(/(?:\r\n|\r|\n)/g, "")
                                }}
                              ></div>
                            ))
                          : ""}
                        <Link to={items.post_permalink} className="learn-more">
                          LEARN MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>,
          this.state.visible < plan_detail.length ? (
            <button onClick={this.loadMore} type="button" className="load-more">
              Load more
            </button>
          ) : (
            ""
          )
        ]
      : [""];
  }
}

export default PlanCard;
