import React from "react";
import HeadingSection from "./HeadingSection";
import "./FocusBoxes.scss";
import $ from "jquery";
import { Accordion, Card, Button } from "react-bootstrap";

class FocusBoxes extends React.Component {
  componentDidMount() {
    $(
      ".accordion-newHomeSection .item:first-child  .itemHead , .accordion-newHomeSection .item:first-child  .imgarea"
    ).addClass("active");
    $(
      ".accordion-newHomeSection .item:first-child .itemHead .mobileAccordion"
    ).slideDown();

    $(".accordion-newHomeSection .item  .itemHead").click(function() {
      if (!$(this).hasClass("active")) {
        $(".accordion-newHomeSection .item  .itemHead").removeClass("active");
        $(this).addClass("active");
        $(".accordion-newHomeSection .item  .imgarea").removeClass("active");
        $(this)
          .siblings(".imgarea")
          .addClass("active");
        $(
          ".accordion-newHomeSection .item .itemHead .mobileAccordion"
        ).slideUp();
        $(this)
          .find(".mobileAccordion")
          .slideDown();
      }
    });
    const section_id = this.props.focus_data.section_id && this.props.focus_data.section_id.trim();
        if (section_id) {
          setTimeout(function () {
              if (window.location.href.indexOf("#" + section_id) > -1) {
                  document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
              }
          }, 2000)
      }
  }

  render() {
    var focus_data = this.props.focus_data;
    const section_id = this.props.focus_data.section_id && this.props.focus_data.section_id.trim();
    return (
      // focus_data.grid_layout.length > 0 ? [
      <section className="main-div"  id={section_id}>
        <div className="container">
          <div className="accordion-newHomeSection">
            <div className="accordionWrapper">
              <div className="mobileImageArea"></div>
              <div className="headerArea">
                <HeadingSection
                  subtitle={focus_data.sub_text}
                  title_part_1={focus_data.headline}
                  title_part_2={focus_data.headline_part_2}
                  headline_part_1_color={focus_data.headline_part_1_color}
                  headline_part_2_color={focus_data.headline_part_2_color}
                />
                {focus_data.description ? (
                  <p
                    className="focus-boxes-subtext no-mr"
                    dangerouslySetInnerHTML={{
                      __html: focus_data.description.replace(
                        /(?:\r\n|\r|\n)/g,
                        ""
                      )
                    }}
                  ></p>
                ) : (
                  ""
                )}
              </div>
              <div className="accordionWrapperItem">
                {focus_data.grid_layout.map((item, key) =>
                  item.grid_headline &&
                  item.grid_description &&
                  item.grid_icon.url ? (
                    <div className="item">
                      <div className="itemHead">
                        {item.grid_headline ? (
                          <h6 className="focus-boxes-heading">
                            {item.grid_headline}
                          </h6>
                        ) : (
                          ""
                        )}
                        <div className="mobileAccordion">
                          <div className="mobileImgArea">
                            {item.grid_icon.url ? (
                              <img
                                src={item.grid_icon.url}
                                title={item.grid_icon.title}
                                alt={item.grid_icon.title}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          {item.grid_description
                            ? [
                                <p
                                  className="mobileDesc"
                                  dangerouslySetInnerHTML={{
                                    __html: item.grid_description.replace(
                                      /(?:\r\n|\r|\n)/g,
                                      ""
                                    )
                                  }}
                                ></p>
                              ]
                            : [""]}
                        </div>
                        {item.grid_description
                          ? [
                              <p
                                className="focus-boxes-description"
                                dangerouslySetInnerHTML={{
                                  __html: item.grid_description.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    ""
                                  )
                                }}
                              ></p>
                            ]
                          : [""]}
                        <div className="arrowRight"></div>
                      </div>
                      <div className="imgarea">
                        <div class="focusimage">
                          {item.grid_icon.url ? (
                            <img
                              src={item.grid_icon.url}
                              title={item.grid_icon.title}
                              alt={item.grid_icon.title}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      // ] : ['']
    );
  }
}

export default FocusBoxes;
