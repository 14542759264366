import React from 'react'

class NoMatch extends React.Component {
  render () {
    return (
      <>
        <div className="page_not">
          Sorry, Page not found.
        </div>
      </>
     
    )
  }
}

export default NoMatch
