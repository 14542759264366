import React from 'react';
import './SectionTitle.scss';

class SectionTitle extends React.Component {
  componentDidMount(){
    const section_id = this.props.section_id && this.props.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }
  split_string(str,count=2,dir='left',splitBy=' ') {
    var part1,part2;
    var split_array = str.split(splitBy);
    if(dir=='right') {
        part2 = split_array.splice(split_array.length-count).join(splitBy);
        part1 = split_array.join(splitBy);
    }else{
        part1 = split_array.splice(0,count).join(splitBy);
        part2 = split_array.join(splitBy);  
    }
    return [part1,part2];
  }
  render() {
    var headline = this.props.headline;
    var subtext = this.props.subtext;
    var card_name = this.props.card_name;
    var splited_title =  headline && this.split_string(headline,1); 
    var highlight_class = 'orange-color';
    var text_class = 'gray-color';
    var section_id = this.props.section_id && this.props.section_id.trim();
    return (
      (headline || subtext || this.props.title_part_1 || this.props.title_part_2 ) ? (
      <div className="sectiontitle-main-div" id={section_id}>
        {subtext && <div className="eyebrow-text-div">
          <div className="sectiontitle-eyebrow_text">{subtext}</div>
          {card_name == 'qmi' ? (<div class="eyebrowtext-border" ></div>):('')}
        </div> }
        {this.props.title_part_1 || this.props.title_part_2 ? (
        <div className="sectiontitle-headline">
        {this.props.title_part_1 && <span style={{color:this.props.headline_part_1_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_1.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
          <span> </span>
          {this.props.title_part_2 && <span style={{color:this.props.headline_part_2_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_2.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
        </div>
        ):(
        headline ? (<div className={"sectiontitle-headline "+(text_class || '')}>
          <span className={highlight_class || ''} dangerouslySetInnerHTML={{__html:splited_title[0].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
          <span> </span>
          <span dangerouslySetInnerHTML={{__html:splited_title[1].replace(/(?:\r\n|\r|\n)/g, '')}}></span> 
        </div>):('')
        )}
   
      </div>):('')
    )
  }
}
export default SectionTitle; 

