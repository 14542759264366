import React from 'react';
import './HeadingSection.scss';

class HeadingSection extends React.Component {  
    split_string(str,count=2,dir='left',splitBy=' ') {
        var part1,part2;
        var split_array = str.split(splitBy);
        if(dir=='right') {
            part2 = split_array.splice(split_array.length-count).join(splitBy);
            part1 = split_array.join(splitBy);
        }else{
            part1 = split_array.splice(0,count).join(splitBy);
            part2 = split_array.join(splitBy);  
        }
        return [part1,part2];
      }
  render() {
    var splited_title =  this.props.title && this.split_string(this.props.title,this.props.highlighted_word_count,this.props.highlight_dir);
    var highlight_class = this.props.highlight_class;
    var text_class = this.props.text_class;
    if(this.props.highlight_dir=='right'){
      highlight_class=text_class;
      text_class = this.props.highlight_class;
    }   
     return (  
          <div className="heading-section"> 
                {this.props.subtitle ? (<p className="sub-title"  style={{color:this.props.subtitle_color}}>{this.props.subtitle}</p>):('')}

                {this.props.title_part_1 || this.props.title_part_2 ? (<h2 className="main-title">
                    {/* {this.props.title_part_1}&nbsp;
                    <span style={{color:this.props.headline_part_2_color}}>
                    {this.props.title_part_2}
                    </span> */}
                    {this.props.title_part_1 && <span style={{color:this.props.headline_part_1_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_1.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                    <span> </span>
                    {this.props.title_part_2 && <span style={{color:this.props.headline_part_2_color}} dangerouslySetInnerHTML={{__html:this.props.title_part_2.replace(/(?:\r\n|\r|\n)/g, '')}}></span>}
                </h2>):this.props.title ? (<h2 className={"main-title "+(text_class || '')}>
                    <span className={highlight_class || ''} dangerouslySetInnerHTML={{__html:splited_title[0].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                    <span> </span>
                    <span dangerouslySetInnerHTML={{__html:splited_title[1].replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                </h2>):('')}
          </div>  
     );  
  }  
}  

export default HeadingSection; 