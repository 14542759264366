import React from 'react';
import HeadingSection from './HeadingSection';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
  nav: true,
  dots: true,
  items : 1,
  loop : false
}

class Testimonials extends React.Component {  
  
  componentDidMount(){
    const section_id = this.props.testimonial_data.section_id && this.props.testimonial_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }
  render() {  
    var testimonial_data = this.props.testimonial_data ;
    const section_id = this.props.testimonial_data.section_id && this.props.testimonial_data.section_id.trim();
     return (  
      testimonial_data.action_content.length > 0 ? (
        testimonial_data.action_content.map(items => (
            items.acf_fc_layout === 'testimonials' ? [
              items.testimonial_content.length > 0 ? (
                <div className="testimonals-main"  id={section_id}>
                {items.testimonial_content.length === 1 ? [
                  items.testimonial_content[0].quote !== '' ? (
                    <div className="spring-section testimonals-section" style={{backgroundColor:items.testimonial_content[0].background_color}}>  
                        <HeadingSection 
                        subtitle={items.testimonial_content[0].sub_text}  
                        title_part_1={items.testimonial_content[0].quote}    
                        title_part_2={items.testimonial_content[0].quote_part_2}  
                        headline_part_1_color = {items.testimonial_content[0].quote_part_1_color}  
                        headline_part_2_color = {items.testimonial_content[0].quote_part_2_color}  

                        />
                        {items.testimonial_content[0].author ?( <p className="small-text">{items.testimonial_content[0].author}</p> ):('')}
                    </div> 
                  ):('')
                ]:[
                  <OwlCarousel {...options} className="owl-theme">  
                  {items.testimonial_content.map(item => (
                    item.quote !== '' ? (
                      <div className="spring-section testimonals-section" style={{backgroundColor:item.background_color}}>  
                          <HeadingSection 
                          subtitle={item.sub_text} 
                          title_part_1={item.quote}    
                          title_part_2={item.quote_part_2}  
                          headline_part_1_color = {item.quote_part_1_color}  
                          headline_part_2_color = {item.quote_part_2_color}  
                          />
                          {item.author ?( <p className="small-text">{item.author}</p> ):('')}
                      </div> 
                    ):('')
                  ))}
                  </OwlCarousel> 
                ]}
                </div>
              ):('')
              ] :['']
          ))
      ):('')
     );  
  }  
}  
export default Testimonials; 