import React from 'react';
import './GenericHero.scss';

class GenericHero extends React.Component {

    componentDidMount(){
        const section_id = this.props.generic_hero.section_id && this.props.generic_hero.section_id.trim();
        if (section_id) {
          setTimeout(function () {
              if (window.location.href.indexOf("#" + section_id) > -1) {
                  document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
              }
          }, 2000)
        }
    }
  
    render() {
        var generic_hero = this.props.generic_hero ;  
        var backgroundImage = generic_hero.generic_image;
        var overlay_hide = generic_hero.hide_overlay == 1 ? '':'add_overlay';
        if(backgroundImage !==""){
            var background_image = {
                backgroundImage:'url('+backgroundImage+')',
            }
        }
        const section_id = this.props.generic_hero.section_id && this.props.generic_hero.section_id.trim();
        return (
            generic_hero.headline || generic_hero.sub_text ? (
                <div className="plan-banner" style={background_image} id={section_id}>
                    <div className={"HeroContent d-flex "+overlay_hide}>
                        <div className="HeroContentWrapper">
                            <div className="bannerHeader">
                                <div className="heading-section">
                                    {generic_hero.headline ? (
                                        <h2 className={"main-title"} style={{color:generic_hero.headline_color}} >
                                            <span dangerouslySetInnerHTML={{__html:generic_hero.headline.replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                                        </h2>
                                    ):('')}
                                    {generic_hero.sub_text ?( 
                                        <p className="generic-text" style={{color:generic_hero.subtext_color}} >{generic_hero.sub_text}</p>
                                    ):('')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ):('')
        );  
  }  
}  

export default GenericHero; 