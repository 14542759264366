import React from 'react';
import axios from 'axios';
import Residence from '../../Components/Residence.jsx';
import Dreamweaver from '../../Components/Dreamweaver.jsx';
import {apiConfig} from '../../Components/Helper.jsx';
import { Redirect } from "react-router-dom";
import NoMatch from './NoMatch.jsx';
import TPHLoader from '../../Components/TPHLoader.jsx';
import DocumentMeta from 'react-document-meta';

class ResidencePage extends React.Component {  
  constructor() {
    super();
    this.state = {
      residence_component : '',
      success : false,
    };
  }
  organization_structured_data(residence_Component){
    if(residence_Component && residence_Component.common_detail) {
      var site_logo = residence_Component.common_detail.site_logo;
    }
    const script = document.createElement("script");
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Altis 55+",
      "url": "${window.location.href}",
      "logo": "${site_logo}",
      "sameAs": [
        "https://twitter.com/tripointehomes",
        "https://www.youtube.com/channel/UCXJMVxh_N_bezo_r5m1TDhg",
        "https://www.pinterest.com/tripointehomes/"
      ]
    }`
      //  Structure Data script end 
    document.head.appendChild(script);

  }
  fetch_data(){
    axios.get(apiConfig().API_URL+'/residence_info/'+this.props.slug)
    .then(response => {
      
      this.setState({
        residence_component : response.data.data,
        success: response.data.success,
      })
      console.log(response);
    })
  }
	componentDidMount(){
    this.fetch_data();
    window.CallTrk.swap();
    window.scrollTo(0,0);
  }

  componentWillReceiveProps(nextProps) {

    if(this.props.slug != nextProps.slug){
      this.props = nextProps;
      this.setState({
        success: false
      })
      this.fetch_data();
    }
    
  } 
  
  componentDidUpdate(){
    if(this.state.success == true){
      window.dataLayer.push({
          'event': 'communityId',
          'divisionName': this.state.residence_component.common_detail.nbd_title ? this.state.residence_component.common_detail.nbd_title : "",
          'e1CommunityId': this.state.residence_component.common_detail.e1_community_id ? this.state.residence_component.common_detail.e1_community_id : "",
          'salesforceId': this.state.residence_component.common_detail.communityOfInterest ? this.state.residence_component.common_detail.communityOfInterest : ""
          
      });
    }
    window.CallTrk.swap();
    }

  render() {    
    var residence_component = this.state.residence_component;
    var success = this.state.success;  
    var message = this.state.message; 
    var state_name = this.props.state.toLowerCase();
    var submarket_name = this.props.submarket.toLowerCase();
    var residence_Component = this.state.residence_component;
    if(residence_Component && residence_Component.common_detail) {
      this.organization_structured_data(residence_Component);
    }
    const meta = {
      title: residence_Component.yoast_title,
      description: residence_Component.yoast_desc,
      canonical: window.location.href
    };
     return (  
          <> 
           <DocumentMeta {...meta} />
            {success === true ? (
                 state_name === residence_component.common_detail.state_abb.toLowerCase() && submarket_name === residence_component.common_detail.submarket.toLowerCase() ? (
                    residence_component.common_detail.post_status !== "Archive" &&  residence_component.common_detail.plan_not_available !== "true"  ? [
                      residence_component.enable_dreamweaver == true && residence_component.dreamweaver_plan_id != '' ? (
                        <Dreamweaver residence_component={residence_component}/>
                      ):(
                        <Residence residence_component={residence_component}/>
                      )
                    ]:[ <Redirect to={residence_component.common_detail.nbd_permalink} /> ]
                 ):(<Redirect to={<NoMatch/>} /> ) 
            ):(message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> )}
          </>  
     );  
  }  
}  

export default ResidencePage; 