import React, { useState } from "react";
import axios from 'axios';
import { Link , useHistory } from 'react-router-dom';
import {apiConfig} from '../Components/Helper.jsx';
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie';


const FloorplanSave = (props) => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const [popup_message, setPopup_message] = useState();
  const [http_code, sethttp_code] = useState();
  const [popup_show, setpopup_show] = useState(false);
  
  function popup_hide(){
    setpopup_show(false);
  }

  function send_floorplan_data(){
    axios.post(apiConfig().API_URL+'/floorplan_save', {
        "altis_user_id":user.sub,
        "altis_plan_id":props.residence_component.common_detail.residence_id,
        "altis_title":props.residence_component.common_detail.residence_title,
        "altis_name":window.floorplan_name,
        "altis_price":props.residence_component.summary.base_price && props.residence_component.common_detail.post_status !== "Sold Out" && props.residence_component.common_detail.post_status !== "Temporarily Sold Out"  && props.residence_component.summary.hide_price !=1 ? (props.residence_component.summary.base_price):(''),
        "altis_url" : window.location.pathname,
        "altis_image":props.residence_component.common_detail.residence_image[0]
       },
       {
         headers: {
           Authorization: 'Aa147147147#'
         }
       }
      )
      .then(response => {
          setPopup_message(response.data.data.status)
          setpopup_show(true);
          sethttp_code(response.data.data.status_code)
      })
      window.dataLayer.push({'event':'saveFloorplan'});
  }

  function  fp_login_redirection(){
    Cookies.set('last_url',window.location.pathname,{ expires: 2 })
    loginWithRedirect();
    window.dataLayer.push({'event':'saveFloorplan'});
  }

  return ( 
     <div>
             { typeof(popup_message) != 'undefined' && popup_show ? [
                <div className="alert_popup">
                  <div className="popup_message">
                      <p>{popup_message}</p>
                      <span onClick={() => popup_hide()}>X</span>
                      {http_code == 200 ? (<p>view it <Link to="/my_account/?tab=floorplans">here</Link></p>):('')}
                  </div>
              </div>
              ]:['']
              }
            {isAuthenticated ? [
            <div className="floorplan_save_btn" onClick={() => send_floorplan_data()}>
                SAVE
            </div>
            ]:[
                <div className="floorplan_save_btn floorplan_login_btn" onClick={() => fp_login_redirection()}>
                SAVE
            </div>
            ]}
     </div> 
  );
};

export default FloorplanSave;