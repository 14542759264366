import React from 'react'
import {BrowserRouter, Switch, Route, withRouter} from 'react-router-dom'
import HomePage from './Pages/HomePage';
import Page_Decider from './Pages/Page_Decider';
import StaticPage from './Pages/StaticPage';
import BlogPage from './Pages/BlogPage';
import BlogListing from './Pages/BlogListing';
import EventsPage from './Pages/EventsPage';
import EventDetailsPage from './Pages/EventDetailsPage';
import LifestyleDetails from './Pages/LifestyleDetails';
import DirectionPage from './Pages/DirectionPage';
import ReviewPage from './Pages/ReviewPage';
import NoMatch from './Pages/NoMatch';
import MyAccount from './Pages/MyAccount';
import ProtectedRoute from "../auth/protected-route";
class Views extends React.Component {  
    render() {  
       return (  
          <div className="main-outer-div">  
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/lifestyle/:slug" component={LifestyleDetails}/> 
              <ProtectedRoute  exact path="/my_account" component={MyAccount} /> 
              <Route exact path="/events" component={EventsPage} />
              <Route exact path="/event/:slug" component={EventDetailsPage}/>
              <Route exact path="/blog" component={BlogListing}/>
              <Route exact path="/blog/:slug" component={BlogPage}/>  
              <Route exact path="/:statename/:submarket/:slug" component={Page_Decider} />
              <Route exact path="/:statename/:submarket/:slug/directions" component={DirectionPage} />
              <Route exact path="/:statename/:submarket/:slug/reviews/" component={ReviewPage}/>
              <Route exact path="/:statename/:submarket/:neigh/:slug" component={Page_Decider}/>
              <Route exact path="/:slug" component={StaticPage}/>
              <Route component={NoMatch}/>
            </Switch>
          </div>  
       );  
    }  
}  
  
export default withRouter(Views);