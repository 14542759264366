import React from 'react';
import './GridSection.scss';
import HeadingSection from './HeadingSection';
class GridSection extends React.Component {

  componentDidMount(){
    const section_id = this.props.grid_data.section_id && this.props.grid_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }

  render() {
    var grid_data = this.props.grid_data;
    const section_id = this.props.grid_data.section_id && this.props.grid_data.section_id.trim();
    return (

      grid_data.focus_box_content.length > 0 ? [
        <div className="container" id={section_id}>
          <div className="grid-section">
            <div className="row">
              {grid_data.focus_box_content.map(items => (
                items.acf_fc_layout === "image" ? (
                  items.image.url ? (
                    <div className="col-lg-4 col-sm-6 grid-img">
                      <div>
                        <img src={items.image.url} title={items.image.title} alt={items.image.title} className="grid-icon" />
                      </div>
                    </div>
                  ) : ('')
                ) : (
                    items.headline || items.description ? (
                      <div className="col-lg-4 col-sm-6 grid-info">
                        <div className="grid-section-content">
                          <HeadingSection
                            title_part_1={items.headline}
                            title_part_2={items.headline_part_2}
                            headline_part_1_color={items.headline_part_1_color}
                            headline_part_2_color={items.headline_part_2_color}
                          />
                          {items.description ? (
                            <p className="title-info" dangerouslySetInnerHTML={{ __html: items.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                          ) : ('')}
                          {items.cta_label && items.cta_link ? (
                            <a href={items.cta_link} className="learn-more" title={items.cta_label}>{items.cta_label}</a>
                          ) : ('')}
                        </div>
                      </div>
                    ) : ('')
                  )
              ))}
            </div>
          </div>
        </div>
      ] : ['']
    );
  }
}

export default GridSection; 