import React from 'react';
import './MortgageSection.scss';
import { Form } from 'react-bootstrap';
import HeadingSection from './HeadingSection';
import $ from 'jquery';

import greenPatternImg from '../../assets/images/green-pattern.png';

class MortgageSection extends React.Component {

    mortgage_calculation() {
        var mor_amount, mor_interest, mor_period, mor_frequency, frequncy_value = '';
        var monthly_value, interest_value, term_value, monthly_amount = '';

        $('.mortgage_field').on('keyup change', function () {

            mor_amount = $('#mortgage_amount').val();
            mor_interest = $('#interest').val();
            mor_period = $('#period').val();
            mor_frequency = $('#frequency').val();
            frequncy_value = $('#frequency option:selected').text();

            if (mor_frequency !== '') {
                $('.frequency_data').text(frequncy_value);
            }

            if (mor_amount !== '' && mor_interest !== '') {
                interest_value = mor_interest / mor_frequency / 100;
                term_value = mor_frequency * mor_period;

                monthly_value = mor_amount * interest_value / (1 - Math.pow(1 + interest_value, -term_value));
                monthly_amount = isNaN(Math.round(monthly_value)) ? 0 : Math.round(monthly_value);
                $('.monthly_amount').text(monthly_amount);
            }

        })
    }

    componentDidMount() {
        this.mortgage_calculation();
        const section_id = this.props.mortgage_data.section_id && this.props.mortgage_data.section_id.trim();
        if (section_id) {
        setTimeout(function () {
            if (window.location.href.indexOf("#" + section_id) > -1) {
                document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
            }
        }, 2000)
        } 
    }
    componentDidUpdate() {
        console.log('Mortage Update');
    }
    componentWillReceiveProps() {
        console.log('Mortage Receive');
    }
    render() {
        var mortgage_data = this.props.mortgage_data;
        var mortgage_subtext = mortgage_data.sub_text ? mortgage_data.sub_text : 'Mortgage calculator';
        var mortgage_option_text = mortgage_data.option_text ? mortgage_data.option_text : 'Enter your mortgage amount';
        var window_width = window.screen.width; 
        const section_id = this.props.mortgage_data.section_id && this.props.mortgage_data.section_id.trim();
        return (
            <div className="mortgage-section-main" id={section_id}>
                <div className="container">
                    <div className="row mortgage-section">
                        <div className="mortgage-content-top">
                            <div className="mortgage-content-info">
                                <HeadingSection
                                    subtitle={mortgage_subtext}
                                    title_part_1={mortgage_data.headline}
                                    title_part_2={mortgage_data.headline_part_2}
                                    headline_part_1_color={mortgage_data.headline_part_1_color}
                                    headline_part_2_color={mortgage_data.headline_part_2_color}
                                />
                                <p className="plan-price">Your <span className="frequency_data">monthly</span> payment would be* $<span className="monthly_amount">0</span></p>
                                {mortgage_data.description ? (
                                    <p className="plan-text" dangerouslySetInnerHTML={{ __html: mortgage_data.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                                ) : ('')}
                                {mortgage_data.cta_label && mortgage_data.cta_link ? (
                                    <a href={mortgage_data.cta_link} className="apply-here" title={mortgage_data.cta_label}>{mortgage_data.cta_label}</a>
                                ) : ('')}
                            </div>
                            {mortgage_data.disclaimer ? (
                                <p className="disclaimer-text"> {mortgage_data.disclaimer}</p>
                            ) : ('')}
                        </div>

                        <div className="mortgage-img">
                            {mortgage_data.form_image ? (
                                <img src={mortgage_data.form_image.url} title={mortgage_data.form_image.title} alt={mortgage_data.form_image.title} />
                            ) : (
                                    <img src={greenPatternImg} title="green pattern" alt="green pattern" />
                                )}
                        </div>
                    </div>
                    <div className="mortgage-form">
                        <Form className="mortgage-calculator">
                            <Form.Group controlId="mortgage_amount">
                                <Form.Label>{mortgage_option_text}</Form.Label>
                                <Form.Control className="mortgage_field" type="Number" />
                            </Form.Group>
                            <Form.Group controlId="interest">
                                <Form.Label>Interest rate (%)**</Form.Label>
                                <Form.Control className="mortgage_field" type="Number" />
                            </Form.Group>
                            <Form.Group controlId="period">
                                <Form.Label>Amortization Period</Form.Label>
                                <Form.Control className="mortgage_field" as="select">
                                    <option value="1">1 Year</option>
                                    <option value="3">3 Years</option>
                                    <option value="5">5 Years</option>
                                    <option value="10">10 Years</option>
                                    <option value="15">15 Years</option>
                                    <option value="25">25 Years</option>
                                    <option value="30" selected="selected">30 Years</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="frequency">
                                <Form.Label>Payment Frequency</Form.Label>
                                <Form.Control className="mortgage_field" as="select">
                                    <option value="12">Monthly</option>
                                    <option value="24">Semi-monthly</option>
                                    <option value="52">Weekly</option>
                                    <option value="26">Bi-weekly</option>
                                </Form.Control>
                            </Form.Group>
                            <p className="price-text">Your <span className="frequency_data">monthly</span> payment would be*</p>
                            <p className="total-price">$<span className="monthly_amount">0</span></p>
                        </Form>
                        {(window_width <= 768) ? (  
                            <div className="mortgage-form-img">
                                {mortgage_data.form_image ? (
                                    <img src={mortgage_data.form_image.url} title={mortgage_data.form_image.title} alt={mortgage_data.form_image.title} />
                                ):('')}  
                            </div>
                        ):('')}
                    </div>
                </div>
            </div>
        );
    }
}

export default MortgageSection; 