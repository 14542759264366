import React from 'react';
import axios from 'axios';
import NoMatch from './NoMatch';
import GlobalField from '../../Components/GlobalField';
import {apiConfig} from '../../Components/Helper.jsx';
import TPHLoader from '../../Components/TPHLoader.jsx';
import { Redirect } from "react-router-dom";
import DocumentMeta from 'react-document-meta';
class HomePage extends React.Component {

  constructor() {
    super();
    this.state = {
      show:true,
      home_component:'',
      plan_cards:'',
      success : false
    };
  }
  organization_structured_data(home_component){
    if(home_component && home_component.site_logo){
      var site_logo = home_component.site_logo;

    }
    const script = document.createElement("script");
   
    // Structure Data script start 
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Altis 55+",
      "url": "${window.location.href}",
      "logo": "${site_logo}",
      "sameAs": [
        "https://twitter.com/tripointehomes",
        "https://www.youtube.com/channel/UCXJMVxh_N_bezo_r5m1TDhg",
        "https://www.pinterest.com/tripointehomes/"
      ]
    }`
      //  Structure Data script end 
    document.head.appendChild(script);

  }
  componentDidMount(){
    axios.get(apiConfig().API_URL+'/home_info/home')
    .then(response => {
      this.setState({
        home_component : response.data.data,
        success: response.data.success,
        message: response.data.message
      })
    });
    window.CallTrk.swap();
  }
  componentDidUpdate(){
    window.CallTrk.swap();
  }
      
  render () {
    var success = this.state.success; 
    var message = this.state.message;
    var home_component = this.state.home_component;
    if(home_component){
      this.organization_structured_data(home_component);
    }
    const meta = {
      title: home_component.yoast_title,
      description: home_component.yoast_desc,
      canonical: window.location.href
    };
    return (
      <>
      <DocumentMeta {...meta} />
        {success === true ? [
          <GlobalField global_data={home_component.component}/>
        ]:[message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} />  ]} 
      </>
    )
  }
}

export default HomePage;
