import React from 'react';
import axios from 'axios';
import Neighborhood from './Neighborhood';
import Community from './Community';
import { apiConfig } from '../../Components/Helper.jsx';
import ResidencePage from './ResidencePage';
import QmiPage from './QmiPage';
import TPHLoader from '../../Components/TPHLoader.jsx';
import { Redirect } from "react-router-dom";
import NoMatch from './NoMatch';

class Page_Decider extends React.Component {

  state = {
    post_type: '',
    success: false,
    check: false
  }
  fetch_data() {
    axios.get(apiConfig().API_URL + '/get_info/' + this.props.match.params.slug)
      //axios.get(apiConfig().API_URL+'/community_info/demo-community')
      .then(response => {
        this.setState({
          post_type: response.data.data,
          success: response.data.success,
          message: response.data.message
        })
        console.log(response);
        //console.log("Data Called");
      })
  }
  componentDidMount() {
    this.fetch_data();
    console.log("Mount");
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps;
    this.fetch_data();
  }
  render() {
    var success = this.state.success;
    var post_type = this.state.post_type;
    var message = this.state.message;
    return (
      success === true ? [
        post_type == 'community' ? [
            <Community slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket}/>
        ]:[
            post_type == 'neighborhood' ? [
                <Neighborhood slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket}/>
            ]:[ 
                post_type == 'residence' ? [
                  <ResidencePage slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket}/>
                ]:[
                  post_type == 'quickmovein' ? [
                    <QmiPage slug={this.props.match.params.slug} state={this.props.match.params.statename} submarket={this.props.match.params.submarket}/>
                  ]:['']
                ]
              ]
            ]
          
    ]:[ message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]
    );
  }
}

export default Page_Decider; 