import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import {apiConfig} from '../../Components/Helper.jsx';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import GlobalField from '../../Components/GlobalField';
import TPHLoader from '../../Components/TPHLoader.jsx';
import DocumentMeta from 'react-document-meta';
class LifestyleDetails extends React.Component {  
  state ={
    lifestyle_data : [],
    success : false,
    message : '',
  }

  fetch_data(){
    axios.get(apiConfig().API_URL + '/lifestyle_detail/' + this.props.match.params.slug)
    .then(response => {
      this.setState({      
        lifestyle_data : response.data.data,
        success: response.data.success,
        message : response.data.message,
      })
      console.log(response);
    })
  }

  componentDidMount(){
    this.fetch_data();
    window.CallTrk.swap();
    window.scrollTo(0,0);
  }
  componentDidUpdate() {
    window.CallTrk.swap();
  }
  render() {    
    var success = this.state.success;
    var message = this.state.message; 
    var lifestyle_data = this.state.lifestyle_data;
    if(lifestyle_data && lifestyle_data.lifestyles) {
        var page_title = lifestyle_data.lifestyles.page_title;
        var page_desc = lifestyle_data.lifestyles.page_desc;
    }
    const meta = {
        title: page_title,
        description: page_desc,
        canonical: window.location.href
      };
    return (  
        <>  
        <DocumentMeta {...meta} />
            {success === true ? [ 
              <GlobalField global_data={lifestyle_data.component} />
              ] : [message != 'Not Completed' ?  <TPHLoader/>  :  <Redirect to={<NoMatch/>} /> ]} 
          
        </>
       ); 
       
  }  
}  

export default LifestyleDetails; 