import React from 'react';
import HeadingSection from './HeadingSection';


class SpringPromo extends React.Component {

  componentDidMount(){
    const section_id = this.props.promo_data.section_id && this.props.promo_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
    }
  }
  render() {
    var promo_data = this.props.promo_data;
    const section_id = this.props.promo_data.section_id && this.props.promo_data.section_id.trim();
    return (
      promo_data.action_content.length > 0 ? (
        promo_data.action_content.map(items => (
          items.acf_fc_layout === 'call_to_action_content' ? (
            items.description && items.headline ? (
              <div className="spring-section" style={{ backgroundColor: items.background_color }} id={section_id}>
                <div className="container">
                  <div className="innersection">
                    <HeadingSection
                      subtitle={items.sub_text}
                      title_part_1={items.headline}
                      title_part_2={items.headline_part_2}
                      headline_part_1_color={items.headline_part_1_color}
                      headline_part_2_color={items.headline_part_2_color}
                    />
                    <div className="text-flex">
                      {items.description ? (<p className="title-info" dangerouslySetInnerHTML={{ __html: items.description.replace(/(?:\r\n|\r|\n)/g, '') }}></p>) : ('')}
                      {items.cta_label && items.cta_link ? (
                        <a href={items.cta_link} title={items.cta_label} className="learn-more" target={items.open_in_new_tab ? '_blank' : '_self'}>{items.cta_label}</a>
                      ) : ('')}
                    </div>
                  </div>
                </div>
              </div>
            ) : ('')
          ) : ('')
        ))
      ) : ('')
    );
  }
}

export default SpringPromo; 