import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {apiConfig} from '../Components/Helper.jsx';
import {Form,Button} from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";

const ProfileInfo = () => {
 
    const { user , getAccessTokenSilently } = useAuth0();
    
    const [user_data, setUser_data] = useState();
    const [token, setToken] = useState();
    const [popup_message, setPopup_message] = useState();
    const [popup_show, setpopup_show] = useState(false);

   function auth0_db(email){
      axios.post('https://tripointehomes.us.auth0.com/dbconnections/change_password',
      {
          "client_id": process.env.Auth0_ClientID,
          "email": email,
          "connection": process.env.Auth0_Connection,
      })
      .then(response => {
          setPopup_message(response.data)
          setpopup_show(true);
      })
    }
    
    function popup_hide(){
      setpopup_show(false);
    }

    function update_profile(id){
      var user_profile_name = document.getElementById("user_profile_name").value;
      axios.post(apiConfig().API_URL+'/profile_update', {
        "name":user_profile_name,
        "altis_user_id" : id
       },
       {
         headers: {
           Authorization: 'Aa147147147#'
         }
       }
      )
      .then(response => {
          console.log("Profile Updated")
          console.log(response)
          setPopup_message(response.data.data.status)
          setpopup_show(true);
      })
    }

    return (
      <div>
              { typeof(popup_message) != 'undefined' && popup_show ? [
                <div className="alert_popup">
                  <div className="popup_message">
                      <p>{popup_message}</p>
                      <span onClick={() => popup_hide()}>X</span>
                  </div>
              </div>
              ]:['']
              }
          <div className="profile_details">
            <Form className="profile_form">
              <Form.Group controlId="user_profile_name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Name" defaultValue={user.name}/>
              </Form.Group>
              
             {/* { typeof(user_data) != 'undefined' ? [
                <Form.Group controlId="user_profile_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Phone Number" defaultValue={typeof(user_data.user_metadata.phone_number) != 'undefined' ? user_data.user_metadata.phone_number : ''}/>
                </Form.Group>
              ]:['']
              } */}
    
              <Form.Group controlId="formBasicCheckbox">
                <Form.Label>Password</Form.Label>
                <Button className="update_pwd_btn" onClick={() => auth0_db(user.email)}>Update Password</Button>
              </Form.Group>
    
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" value={user.email} disabled/>
              </Form.Group>
              <Button className="update_profile_btn" onClick={() => update_profile(user.sub)} variant="primary" type="button">
                Update Settings
              </Button>
            </Form>
          </div>
      </div>
    );
}

export default ProfileInfo