import React from 'react';
import axios from 'axios';
import './Footer.scss';
import { apiConfig } from '../Components/Helper.jsx';
import designedBy from '../../assets/images/tripintehomes.png';
import lifeImg from '../../assets/images/Life-Changing-by-Design.png';
import copyIcon from '../../assets/images/copyIcon.png';

class Footer extends React.Component {

  state = {
    footer_info: [],
    success: false,
    is_visible: false
  }

  componentDidMount() {
    axios.get(apiConfig().API_URL + '/footer_info')
      .then(response => {
        this.setState({ footer_info: response.data.data, success: response.data.success })
        /* console.log(response);*/
      })

    window.CallTrk.swap();
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }
  toggleVisibility(){
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }
  socialShare_data_layer_push(e) {
    if(e.target.className==="fa fa-youtube-play") {
      window.dataLayer.push({
        'event':'socialShare',
        'socialName': 'Social - YouTube'
    });
    }
    if(e.target.className==="fa fa-instagram") {
      window.dataLayer.push({
        'event':'socialShare',
        'socialName': 'Social - Instagram'
      });
    }
    if(e.target.className==="fa fa-linkedin") {
      window.dataLayer.push({
        'event':'socialShare',
        'socialName': 'Social - LinkedIn'
      });
    }
    if(e.target.className==="fa fa-facebook") {
      window.dataLayer.push({
        'event':'socialShare',
        'socialName': 'Social - Facebook'
      });
    }
  }
  componentDidUpdate() {
    window.CallTrk.swap();
  }
  scrollToTop() {
    window.scrollTo({
       top: 0,
       behavior: "smooth"
    });
  }
  
  render(){
    var success = this.state.success;
    var footer_details = this.state.footer_info;
    var is_visible = this.state.is_visible;
    var visible = {
      opacity: is_visible ? "1" : "0"
    };
    return (
      <>
        <div class="top-scroll" onClick={ () => { this.scrollToTop(); }} style={visible}><i class="fa fa-angle-double-up" aria-hidden="true"></i><p>TOP</p></div>
        <div className="footer_section">
          {
            success === true ? [
              <div className="footer-top">
                <div className="footer-social">
                  <div className="designed-by-logo">
                    <img src={designedBy} title="Designed By tri Pointe Homes" alt="tri-pointe" />
                  </div>
                  <div className="socialContent">
                    <ul>
                      {
                        footer_details.footer_menu.map(items => (
                          <li><span><a href={items.url}>{items.title}</a></span></li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>,
              <div className="footer-middle">
                <div className="footer-logo">
                  <div className="social_links">
                    {
                      footer_details.social_menu.map(items => (
                        <a href={items.url} rel="noopener noreferrer" target="_blank" title={items.title} onClick={(e) => this.socialShare_data_layer_push(e)}><i className={"fa " + items.classes[0]} aria-hidden="true"></i></a>
                      ))
                    }
                  </div>
                  <div className="life-group-logo">
                    <img src={lifeImg} title="life inspiring" alt="life-inspiring" />
                  </div>
                </div>
              </div>,
              <div className="footer-lower">
                <div className="footer-copyright">
                  <div className="copyrightLinks-div">
                    <div className="copyrightCaption">
                      <p>© {new Date().getFullYear()} Tri Pointe Homes, Inc. All rights reserved.</p>
                    </div>
                    <div className="copyrightLinks">
                      <ul>
                        {
                          footer_details.copyright_menu.map(items => (
                            <li><a href={items.url} target="_blank">{items.title}</a></li>
                          ))
                        }
                      </ul>
                      <span>
                        <img src={copyIcon} title="copyIcon" alt="copyIcon" />
                      </span>
                    </div>
                  </div>
                  <div className="copyright-text" dangerouslySetInnerHTML={{ __html: footer_details.footer_disclaimer.replace(/(?:\r\n|\r|\n)/g, '') }}></div>
                </div>
              </div>
            ] : ['']
          }
        </div>
      </>
    );
  }
}

export default Footer; 