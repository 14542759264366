import React from "react";
import "./HomeBanner.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class HomeBanner extends React.Component {

  componentDidMount(){
    const section_id = this.props.banner_data.section_id && this.props.banner_data.section_id.trim();
    if (section_id) {
      setTimeout(function () {
          if (window.location.href.indexOf("#" + section_id) > -1) {
              document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth',block:'start'});
          }
      }, 2000)
  }
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };
    var banner_data = this.props.banner_data.home_banner_slider;
    const section_id = this.props.banner_data.section_id && this.props.banner_data.section_id.trim();
    return (
      <section className="home-banner-section" id={section_id}>
        <div className="container">
          <div className="banner_content">
            <div className="banner_slider">
              <Slider {...settings}>
                {banner_data.map(item => (
                <div>
                    <div class="test-banner">
                      {item.banner_headline ||
                      (item.button_link && item.button_label) ? (
                        <div className="banner_title divide-equal">
                          <h2 dangerouslySetInnerHTML={{ __html: item.banner_headline.replace(/(?:\r\n|\r|\n)/g, '') }}></h2>
                          {item.button_link && item.button_label ? (
                            <Link
                              to={item.button_link}
                              title={item.button_label}
                              className="link"
                            >
                              {item.button_label}
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {item.banner_image ? (
                        <div className="banner_image divide-equal">
                          <img
                            className="headerLogo"
                            src={item.banner_image.url}
                            alt={item.banner_image.title}
                            title={item.banner_image.title}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HomeBanner;
